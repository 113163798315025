import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AvatarLocation from '../components/AvatarLocation';
import { useUserData } from '../context/UserData';
import ModalWindow from '../components/ModalWindow';
import ModalCarryOverSection from '../components/ModalCarryOverSection';
import DateFormat from '../components/DateFormat';
import LoadingSpinner from './LoadingSpinner';
import { dateToUnixTimestamp, addDays } from '../utils/dateToUnixTimestamp';
import { getTodaysDateUtil } from '../utils/DateFormatTodayUtil';
import HeadingCp from './HeadingCp';
import { ca } from 'date-fns/locale';

const CarryOverCP = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/settings_crud.php?getCarryOver`;
  const [loading, setLoading] = useState(true);
  const [access, setAccess] = useState(false);
  const navigate = useNavigate();
  const key = useParams();
  const [week, setWeek] = useState(getTodaysDateUtil());
  const [carryOver, setCarryOver] = useState({
                                                id: '',
                                                employee_id: '',
                                                total_days: '',
                                                year: ''
                                                });
  const [openModalId, setOpenModalId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [payperiod, setPayperiod] = useState('');
  const [classMod, setClassMod] = useState({});
  const userData = useUserData();
  const [deleteButton, setDeleteButton] = useState(false)
  const [bodyData, setbodyData] = useState({
    status: 'submit_co',
    data: {}
  });

  const [payperiodMonth, setPayPeriodMonth] = useState(null);//encapsulate
  const [monthWeek, setMonthWeek] = useState('active_month');

  useEffect(() => {
    const fetchOvertime = async () => {
      try {
        const response = await axios.get(`${apiUrl}/settings_crud.php`, {
          params: { getCarryOver: true},
          headers: {  'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'}
        }); 
        const employeeList = Array.isArray(response.data) ? response.data : [];
        if (employeeList.length === 0) {
          toast.error("No employees found!", {
            position: "top-center"
          });
        }
        setFilteredEmployees(employeeList);
        setCarryOver(employeeList);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", {
            position: "top-center"
          });
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          setAccess(true);
          toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
            position: "top-center"
          });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchOvertime();
  }, [key, apiUrl, navigate, week, monthWeek]);

  
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value === '') {
      setFilteredEmployees(carryOver);
    } else {
      const filtered = carryOver.filter(employee =>
        (employee.department && employee.department.toLowerCase().includes(value.toLowerCase())) ||
        (employee.first_name && employee.first_name.toLowerCase().includes(value.toLowerCase())) ||
        (employee.last_name && employee.last_name.toLowerCase().includes(value.toLowerCase())) ||
        (employee.title && employee.title.toLowerCase().includes(value.toLowerCase()))
      );
      setFilteredEmployees(filtered);
    }
  };

  const handleCarryOver = (employee_id) => {
    setOpenModalId(employee_id);
  };

  const handleChange = (event, id, user, carry_over_id) => {
      const { value } = event.target;
        setFilteredEmployees(prev => prev.map(carryOver => {
            if (parseInt(carryOver.e_id) === id) {
                const carryOverDays = { ...carryOver, total_days: value };
                 bodyData.data.total_days = carryOverDays.total_days;
                 bodyData.data.employee_id = carryOverDays.e_id;
                 bodyData.data.carry_over_id = carry_over_id;
                 setClassMod(prev => ({ ...prev, [id]: 'text-green-600 text-md' })); 
          return carryOverDays;
                } else {
                    return carryOver;
                }
            }));
      };


  return (
    <>
      {access ? (
        "No access"
      ) : (
        <>
          {loading ? (
            <LoadingSpinner children={'Loading....'}/>
          ) : (
            <>
              <div className="relative rounded-md shadow-sm flex-grow">
                <input
                  type="text"
                  name="search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className="block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                  placeholder="Filter by Department or Title"
                />
              </div>
              <div className='py-6'>
                <HeadingCp label={"Carry-Over Day(s)"}/>
              </div>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                {filteredEmployees.filter(employee => employee.title !== 'President').map((employee) => (
                  <div key={employee.e_id}
                        className="relative flex items-center space-x-3 rounded-lg border 
                                border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 
                                focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400">
                    <div className="flex-shrink-0">
                      <AvatarLocation avatar={employee.avatar} className={"h-10 w-10 rounded-full"} />
                    </div>
                    <div className="min-w-0 flex-1">
                      <button onClick={() => handleCarryOver(employee.e_id)} className="focus:outline-none">
                        <span className="absolute inset-0" aria-hidden="true" />
                        <p className="text-sm font-medium text-gray-900">{employee.first_name + " " + employee.last_name}</p>
                        <p className="truncate text-sm text-gray-600">{employee.title}</p>
                        <p className="truncate text-sm text-blue-500">{employee.department}</p>
                      </button>

                      {openModalId === employee.e_id && (
                        <ModalWindow job={
                                <ModalCarryOverSection
                                  onChange={(e) => handleChange(e, employee.e_id, userData?.users, employee.carry_over_id)}
                                  api={api}
                                  inside={'total_days'}
                                  bodyData={bodyData}
                                  val={employee || ''}
                                  id={employee.e_id}
                                  deleteOT={{employee:employee.e_id, ot_id:employee.ot_id}}
                                  closeNotes={() => setOpenModalId(null)}
                              />
                          } closeNotes={() => setOpenModalId(null)}/>
                      )}
                    </div>
                    <div className="flex-shrink-0 text-right">
                      <p className={`text-gray-500 text-md ${classMod[employee.id]}`}>
                        {parseInt(employee.total_days) > 1 ? 'Days' : 'Day'}
                      </p>
                      <p className={`text-gray-500 text-md ${classMod[employee.id]}`}>
                        {employee.total_days ? employee.total_days : 0}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <br />
              <br />
              <br />
            </>
          )}
        </>
      )}
    </>
  );
};

export default CarryOverCP;
