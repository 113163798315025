import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LoadingSpinner from '../components/LoadingSpinner';
import DateFormat from '../components/DateFormat';
import { useUserData } from '../context/UserData';
import { useNavigate, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HeadingCp from '../components/HeadingCp';
import MonthTabs from '../components/MonthTabs';
import Truncate from '../components/Truncate';
import { DocumentCheckIcon } from '@heroicons/react/24/outline';
import SubMenu from '../components/SubMenu';
import LegendCP from '../components/LegendCP';
import SearchBarCP from '../components/SearchBarCP';
import { set } from 'date-fns';

const InspectionsPage = () => {
  const [inspections, setInspections] = useState([]);
  const [loading, setLoading] = useState(true);
  const userData = useUserData();
  const navigate = useNavigate();
  const [currentMonth, setCurrentMonth] = useState(() => {
    const date = new Date();
    return String(date.getMonth() + 1).padStart(2, '0');
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/contracts_crud.php`;
  const months = [
      { name: 'All', value: 'all' },
      { name: 'January', value: '01' },
      { name: 'February', value: '02' },
      { name: 'March', value: '03' },
      { name: 'April', value: '04' },
      { name: 'May', value: '05' },
      { name: 'June', value: '06' },
      { name: 'July', value: '07' },
      { name: 'August', value: '08' },
      { name: 'September', value: '09' },
      { name: 'October', value: '10' },
      { name: 'November', value: '11' },
      { name: 'December', value: '12' },
  ];
  const {ready, setReady} = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterInspection, setFilterInspection] = useState([]);
  

  useEffect(() => {
    fetchInspections();
  }, []);

  const fetchInspections = async (selectedMonth = currentMonth) => {
  setLoading(true);
  try {
    const response = await axios.get(api, {
      params: {
        getInspections: 'all',
        month: selectedMonth,
      },
    });
    if (response.data) {
      setInspections(response.data);
      setFilterInspection(response.data);
    } else {
      toast.info("no inspections", {
          position: "top-center",
        });
    }
  } catch (error) {
      // Handle errors
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center",
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Access denied.", {
          position: "top-center",
        });
      } else {
        toast.error("Error fetching inspections", {
          position: 'top-center',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleMonthChange = (monthValue) => {
    setCurrentMonth(monthValue);
    fetchInspections(monthValue);
  };

  const handleReady = async(inspection_id, billingStatus, status) => {
    if(status === 'pending') {
      toast.info('Inspection is pending', { position: 'top-center' });
      return;
    } 
    
    try {
      const response = await axios.post(api, {
        status: 'readyBilling',
        data: {
          inspection_id: inspection_id,
          billing_status: billingStatus
        }
      }, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data.success) {
        toast.success('updated!', { position: 'top-center' });
        fetchInspections();
      } else {
        toast.error(response.data.error || 'Error updating', { position: 'top-center' });
      }
    } catch (error) {
      console.error('Error updating billing', error);
      toast.error('Error updating billing', { position: 'top-center' });
    } 
  };

    const handleFile = (contractId) => {};
    const voidContractSubmit = (contractId) => {};

    // 4) getLinksForContract updated to open the new modal
    const getLinksForContract = (contract) => [
      {
        name: contract?.billing_status ? 'Remove Billable' : 'Billable',
        click: () => handleReady(contract.inspection_id, contract.billing_status ? 'not_ready' : 'ready', contract.status),
      },
      {
        name: 'Upload Invoice',
        click: () => handleFile(contract.inspection_id)
      }
    ];

    const handleSearchChange = (event) => {
      
      const value = event.target.value;
      setSearchTerm(value);
      if (value === '') {
        setFilterInspection(inspections);
      } else {
        const filtered = inspections.filter(inspection =>
          (inspection.job_site_address && inspection.job_site_address.toLowerCase().includes(value.toLowerCase())) ||
          (inspection.customer_name && inspection.customer_name.toLowerCase().includes(value.toLowerCase())) ||
          (inspection.contract_type && inspection.contract_type.toLowerCase().includes(value.toLowerCase()))
        );
        setFilterInspection(filtered);
      }
    };

    const handleSearchClick = async () => {
      setLoading(true);
      const bodyData = {
        status: 'searchInspections',
        data: { search: searchTerm }
      };

      try {
        const response = await axios.post(api, bodyData, {
          headers: { 'Content-Type': 'application/json' }
        });
        if (response.data && Array.isArray(response.data)) {
          setInspections(response.data);
          setFilterInspection(response.data);
        } else {
          setFilterInspection([]);
          toast.error('No results found for: ' + searchTerm, { position: 'top-center' });
        }
      } catch (error) {
        console.error('Error searching contracts', error);
        toast.error('Error searching contracts', { position: 'top-center' });
      } finally {
        setLoading(false);
      }
    };


  return (
    <div className="mx-auto max-w-[1250px] px-4 sm:px-6 lg:px-8 py-8">
      <HeadingCp label="Inspections" />
      {/* Month Tabs */}
      <MonthTabs
        months={months}
        currentMonth={currentMonth}
        onMonthChange={handleMonthChange}
      />      
      <br/>
      <LegendCP color="fill-green-300" label="Ready For Billing" />
      <br/>
      <br/>
    <div className="flex space-x-3 items-center mb-10">
     <button
          onClick={() => navigate(`/maintenance`)}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium 
                    rounded-md shadow-sm text-white bg-green-600 hover:bg-green-400 focus:outline-none 
                    focus:ring-2 focus:ring-offset-2 focus:ring-black-500">
          Contracts
      </button>

        <button
          onClick={() => navigate('/inspection_calendar')}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium 
                    rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-400 focus:outline-none 
                    focus:ring-2 focus:ring-offset-2 focus:ring-black-500">
          Scheduler
        </button>

      <SearchBarCP search={searchTerm} handleChange={handleSearchChange} onClick={handleSearchClick} />      
      </div>


      {loading ? (
        <LoadingSpinner>Loading...</LoadingSpinner>
      ) : (
        
        <table className="min-w-full divide-y divide-gray-300 bg-gray-200 border rounded-lg">
          <thead>
            <tr className="divide-x divide-gray-400">
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                ID
              </th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Inspection site | Customer
              </th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Schedule Contact
              </th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Report
              </th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Scheduled Date
              </th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Actual Inspection Date
              </th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Status
              </th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Tech Notes
              </th>
              {/* Add other headers as needed */}
            </tr>
          </thead>
          <tbody>
            
            {filterInspection?.map((inspection, index) => 
            {
              const rowClassName = () => {
                  if (inspection.billing_status === 'ready') {
                    return 'bg-green-100 divide-x';
                  } else {
                    return `${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'} divide-x`;
                  }
                };
              return (
                <tr key={inspection.inspection_id} className={rowClassName()}>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    <div className="text-sm text-gray-400">
                     <SubMenu label={inspection.inspection_id} 
                                  links={getLinksForContract(inspection)} 
                                  idItem={inspection.inspection_id}/>
                    </div>
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    <span className='font-bold text-black'>
                        {inspection.job_site_address}
                        {inspection.job_site_city && (' '+ inspection.job_site_city + ', ')} 
                        {inspection.job_site_state && (' '+ inspection.job_site_state + ' ')} 
                        {inspection.job_site_zip && (' '+ inspection.job_site_zip)} 
                    </span>
                    <p className="text-sm text-gray-400">{inspection.customer_name}</p>
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                        {inspection.contact_name}
                        <p>
                          {inspection.contact_phone && (' '+ inspection.contact_phone + ', ')} 
                        </p>
                        <p>
                          {inspection.contact_email && (' '+ inspection.contact_email + ' ')}
                        </p>
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {/* file */}
                    <span className='text-gray-700 p-1'>
                      {(inspection.customer_signature) && (
                        <NavLink 
                          to={`/maintenance_ticket/${inspection.id}`} 
                          target="_blank" 
                          rel="noopener noreferrer"
                        >
                          <DocumentCheckIcon className="w-5" />
                        </NavLink>
                      )}
                    </span>                  
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    <DateFormat date={inspection.scheduled_due_date} />
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    <DateFormat date={inspection.actual_inspection_date} />
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {inspection.status}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {inspection.tech_notes &&  <Truncate text={inspection.tech_notes} />}
                  </td>
                  {/* <td className="px-3 py-4 text-sm text-gray-500">
                    <button onClick={() => navigate(`/inspection/${inspection.id}`)}>
                      {inspection.id}
                    </button>
                  </td> */}

                </tr>
              )
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default InspectionsPage;
