import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink, useNavigate } from 'react-router-dom';
import SubMenu from '../components/SubMenu';
import DateFormat from '../components/DateFormat';
import SearchBarCP from '../components/SearchBarCP';
import Modal from '../components/Modal';
import ModalWindow from '../components/ModalWindow';
import ModalNotesCp from '../components/ModalNotesCp';
import { useUserData } from '../context/UserData';
import LoadingSpinner from '../components/LoadingSpinner';
import COTtopTabs from '../components/COTtopTabs.js';
import CurrencyFormat from '../components/CurrencyFormat';
import PhoneNumber from '../components/PhoneNumber.js';
import { ArrowUpCircleIcon } from '@heroicons/react/24/outline';
import Truncate from '../components/Truncate';
import CotFileUploadModal from '../components/CotFileUploadModal';
import { Dialog, Transition } from '@headlessui/react';
import SelectCustomers from '../components/SelectCustomers';
import CotDetails from './CotDetails.js';
import Badge from '../components/Badge.js';
import FileLocation from '../components/FileLocation.js';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const CotBillingPage = () => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/cot_billing_contracts.php`;
  const [newContract, setNewContract] = useState(false);
  const [newContractData, setNewContractData] = useState({
    customer_id: '',
    customer_name: '',
    contract_address: '',
    contract_account_number: '',
    contact_name: '',
    contact_phone: '',
    contact_ext: '',
    contact_email: '',
    contract_price: '',
    contract_signed: '',
    frequency: '6', // Default to Semi-Annual
    file: null,
    notes: '',
  });

  const [contracts, setContracts] = useState([]);
  const [filteredContracts, setFilteredContracts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalFileOpen, setIsModalFileOpen] = useState(false);
  const [selectedContractId, setSelectedContractId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [notesIdOpen, setNotesIdOpen] = useState(null);
  const userData = useUserData();
  const [count, setCount] = useState(0);
  const [selectedFileId, setSelectedFileId] = useState(null);

  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth()); // 0 (January) to 11 (December)
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // New state variables for editing contracts
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editContractData, setEditContractData] = useState(null);

  const handleMonthClick = (monthIndex) => {
    setCurrentMonth(monthIndex);
    fetchContractsForMonth(monthIndex);
  };

  const fetchContractsForMonth = async (monthIndex) => {
    setLoading(true);
    let url = `${apiUrl}/cot_crud.php?getContracts=true`;

    if (monthIndex !== -1) {
      const month = monthIndex + 1; // JavaScript months are 0-indexed, but backend might expect 1-12
      url += `&month=${month}`;
    } else {
      url += `&all=true`;
    }

    try {
      const response = await axios.get(url, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
      });
      const contractsData = Array.isArray(response.data[0]) ? response.data[0] : [];
      const count = response.data[1] > 0 ? response.data[1] : 0;

      if (contractsData.length === 0) {
        toast.info(`No contracts found.`, { position: "top-center" });
      }
      setFilteredContracts(contractsData);
      setContracts(contractsData);
      setCount(count);

    } catch (error) {
      toast.error("Error fetching data.", { position: "top-center" });
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", { position: "top-center" });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Access denied. Speak to the administrator.", { position: "top-center" });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleNotesView = (id) => {
    setNotesIdOpen(id);
  };

  // Generate month tabs, including 'All'
  const monthTabs = [
    {
      name: 'All',
      current: currentMonth === -1,
      onClick: () => handleMonthClick(-1),
    },
    ...Array.from({ length: 12 }, (_, index) => {
      const date = new Date(0, index);
      const monthName = date.toLocaleString('default', { month: 'short' }); // 'Jan', 'Feb', etc.
      return {
        name: monthName,
        current: index === currentMonth,
        onClick: () => handleMonthClick(index),
      };
    }),
  ];

  useEffect(() => {
    fetchContractsForMonth(currentMonth);
  }, [currentMonth, apiUrl, navigate]); 

  const [bodyDataNotes, setBodyDataNotes] = useState({
    status: 'submit_cot_notes',
    employee_id: null,
    data: {},
  });

  const handleFormSubmit = async (file, contractId) => {
    if (!file) {
      toast.error("Please upload a valid file.", { position: 'top-center' });
      return;
    }
    const formData = new FormData();
    formData.append('status', 'upload_contract_file');
    formData.append('id', contractId);
    formData.append('file', file);
    try {
      const response = await axios.post(`${apiUrl}/cot_billing_contracts.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setFilteredContracts(prev => prev.map(item => {
        if (item.id === parseInt(response.data.id)) {
          return { ...item, file: response.data.file };
        }
        return item;
      }));

      if (response.data.success) {
        toast.info('File uploaded successfully!', { position: 'top-center' });
      } else {
        toast.error(response.data.error, { position: 'top-center' });
      }
    } catch (error) {
      toast.error("Error uploading file.", { position: "top-center" });
      if (error.response && error.response.status === 401) {
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Access denied. Speak to the administrator.", { position: "top-center" });
      }
    }
  };

  const getLinksForContract = (contract) => {
    let links = [
      { name: 'Edit Contract', click: () => navigate(`/new_cot/${contract.id}`), key: `edit-${contract.id}` },
      {
        name: contract.file ? 'Update Contract File' : 'Upload File',
        click: () => { setIsModalFileOpen(true); setSelectedContractId(contract.id) },
        key: `upload-file-${contract.id}`
      },
      // { name: 'Delete Contract', click: () => handleDelete(contract.id), key: `delete-contract-${contract.id}` },
    ];
    return links;
  };

  const toggleContractStatus = async (id, status) => {
    setLoading(true);
    const bodyData = {
      status: 'update_contract_status',
      data: { contract_id: id, contract_status: status }
    };

    try {
      const response = await axios.post(`${apiUrl}/cot_billing_contracts.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data.success) {
        setFilteredContracts(objects => objects.map(item => {
          if (item.id === id) {
            return { ...item, status: status };
          }
          return item;
        }));

        toast.success('Status updated!', { position: 'top-center' });
      } else {
        toast.error('Error updating status', { position: 'top-center' });
      }
    } catch (error) {
      console.error('Error updating status', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event, id, user) => {
    const { name, value } = event.target;
    setFilteredContracts(prev => prev.map(item => {
      if (parseInt(item.id) === id) {
        const updatedContract = { ...item, [name]: value };
        bodyDataNotes.data = updatedContract;
        updatedContract.employee_name = `${user.first_name} ${user.last_name}`;
        return updatedContract;
      } else {
        return item;
      }
    }));
    setBodyDataNotes(prev => ({
      ...prev,
      employee_id: user.id,
      data: { ...prev.data, [name]: value }
    }));
  };

  const handleSearchClick = async () => {
    if (!searchTerm) {
      toast.info('Search bar is empty', { position: 'top-center' });
    } else {
      setLoading(true);

      const bodyData = {
        status: 'search',
        data: { search: searchTerm }
      };
      try {
        const response = await axios.post(`${apiUrl}/cot_crud.php`, bodyData, {
          headers: { 'Content-Type': 'application/json' }
        });
        if (response.data && Array.isArray(response.data)) {
          setContracts(response.data);
          setFilteredContracts(response.data);
        } else {
          handleSearchChange({ target: { value: '' } });
          setContracts([]);
          setFilteredContracts([]);
          toast.error(`Not found: ${searchTerm}`, { position: 'top-center' });
        }
      } catch (error) {
        console.error('Error searching contracts', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value === '') {
      setFilteredContracts(contracts);
    } else {
      const filtered = contracts.filter(contract =>
        (contract.contract_account_number && contract.contract_account_number.toLowerCase().includes(value.toLowerCase())) ||
        (contract.contact_name && contract.contact_name.toLowerCase().includes(value.toLowerCase())) ||
        (contract.customer_name && contract.customer_name.toLowerCase().includes(value.toLowerCase())) ||
        (contract.contract_address && contract.contract_address.toLowerCase().includes(value.toLowerCase()))
      );
      setFilteredContracts(filtered);
    }
  };

  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    setNewContractData((prev) => ({ ...prev, [name]: value }));
  };

  const handleNewContract = async (e) => {
    e.preventDefault();
    if (newContractData.contract_account_number === '') {
      toast.error("Contract account number is required", { position: "top-center" });
      return;
    }
    const bodyData = {
      data: newContractData,
      status: 'submit_new_contract'
    };
    try {
      const response = await axios.post(`${apiUrl}/cot_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data.success) {
        toast.success("Contract successfully saved!", { position: "top-center" });
        setFilteredContracts((prev) => [response.data[0], ...prev]);
        setContracts((prev) => [response.data[0], ...prev]);
        setNewContract(false);
      } else {
        toast.error(response.data.error || "Failed to save contract.", { position: "top-center" });
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.", { position: "top-center" });
    }
  };

  const handleDelete = async (contractId) => {
    try {
      const response = await axios.post(`${apiUrl}/cot_billing_contracts.php`, {
        status: 'delete_contract',
        data: { contract_id: contractId },
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data.success) {
        toast.success("Contract deleted successfully!", {
          position: "top-center"
        });
        setFilteredContracts(prev => prev.filter(item => item.id !== contractId));
        setContracts(prev => prev.filter(item => item.id !== contractId));
      } else {
        toast.error(response.data.message || "Failed to delete contract!", {
          position: "top-center"
        });
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.", {
        position: "top-center"
      });
    }
  };

  const handleUploadFile = async (e, type) => {
    e.preventDefault();
    if (!file) {
      toast.error("Please select a file before uploading.", { position: 'top-center' });
      return;
    }
    const formData = new FormData();
    formData.append('status', 'submit_file');
    formData.append('id', selectedContractId); // Include the contract ID
    formData.append('file', file);
    formData.append('type', type);
    
    try {
      const response = await axios.post(`${apiUrl}/cot_file_upload.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      if (response.data.success) {
        toast.info('File uploaded successfully!', { position: 'top-center' });
        setFilteredContracts(prev => 
          prev.map(contract =>
            contract.id === selectedFileId ? 
            { ...contract, file: response.data.cot_billing.file } : contract
          )
        );
        setContracts(prev => 
          prev.map(contract =>
            contract.id === selectedFileId ? 
            { ...contract, file: response.data.cot_billing.file } : contract
          )
        );     
        setFile(null);
        setIsModalFileOpen(false);
      } else {
        toast.error(response.data.error || "Error uploading file", { position: 'top-center' });
      }
    } catch (error) {
      toast.error("An error occurred during file upload.", { position: 'top-center' });
    }
  };

   // Handle file selection for upload
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      toast.error('No file selected.', { position: 'top-center' });
      setFile(null);
      return;
    }

    const validFileTypes = ['application/pdf', 'image/png', 'image/jpeg'];
    const maxSize = 2 * 1024 * 1024; // 2MB in bytes

    if (!validFileTypes.includes(selectedFile.type)) {
      toast.error('Invalid file type. Only PDF, PNG, and JPEG are allowed.', {
        position: 'top-center',
      });
      setFile(null);
      return;
    }

    if (selectedFile.size > maxSize) {
      toast.error('File size exceeds 2MB.', { position: 'top-center' });
      setFile(null);
      return;
    }

    setFile(selectedFile);
  };  

  const handleCompanySelect = (newCompany) => {
    if (newCompany && newCompany.customer_name) {
      setSelectedCompany(newCompany);
      setNewContractData(prev => ({
        ...prev,
        customer_name: newCompany.customer_name,
        customer_id: newCompany.id
      }));    
    } else {
      console.error('Invalid company selected:', newCompany);
    }
  };

  const editContract = (contractId) => {
    const contractToEdit = contracts.find((contract) => contract.id === contractId);

    // Ensure no null values in contractToEdit
    const contractWithDefaults = {
      ...contractToEdit,
      contract_address: contractToEdit.contract_address || '',
      contract_account_number: contractToEdit.contract_account_number || '',
      contact_name: contractToEdit.contact_name || '',
      contact_phone: contractToEdit.contact_phone || '',
      contact_ext: contractToEdit.contact_ext || '',
      contact_email: contractToEdit.contact_email || '',
      contract_price: contractToEdit.contract_price !== null ? contractToEdit.contract_price : '',
      contract_signed: contractToEdit.contract_signed || '',
      frequency: contractToEdit.frequency !== null ? contractToEdit.frequency.toString() : '',
      notes: contractToEdit.notes || '',
      customer_id: contractToEdit.customer_id !== null ? contractToEdit.customer_id : '',
      customer_name: contractToEdit.customer_name || '',
    };

    setEditContractData(contractWithDefaults);
    setIsEditModalOpen(true);
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditContractData((prev) => ({ ...prev, [name]: value }));
  };

  const handleEditCompanySelect = (newCompany) => {
    if (newCompany && newCompany.customer_name) {
      setEditContractData((prev) => ({
        ...prev,
        customer_name: newCompany.customer_name,
        customer_id: newCompany.id,
      }));
    } else {
      console.error('Invalid company selected:', newCompany);
    }
  };

  const handleEditContractSubmit = async (e) => {
    e.preventDefault();

    // Prepare the data to send to the backend
    const bodyData = {
      status: 'submit_new_contract',
      data: editContractData,
    };
    try {
      const response = await axios.post(`${apiUrl}/cot_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.data.success) {
        toast.success('Contract updated successfully!', { position: 'top-center' });

        // Update the contracts and filteredContracts state with the updated contract
        setContracts((prevContracts) =>
          prevContracts.map((contract) =>
            contract.id === editContractData.id ? editContractData : contract
          )
        );
        setFilteredContracts((prevContracts) =>
          prevContracts.map((contract) =>
            contract.id === editContractData.id ? editContractData : contract
          )
        );

        // Close the edit modal
        setIsEditModalOpen(false);
      } else {
        toast.error(response.data.error || 'Failed to update contract.', { position: 'top-center' });
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.', { position: 'top-center' });
      console.error('Error updating contract:', error);
    }
  };


  // A small helper to toggle auto_renew from '1' (checked) to '0' (unchecked):
  const handleAutoRenewCheckbox = (e) => {
    const checked = e.target.checked;
    handleNewInputChange({
      target: {
        name: "auto_renew",
        value: checked ? "1" : "0",
      },
    });
  };  

  return (
    <div className="mx-auto max-w-6xl sm:px-6 lg:px-8 py-10">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900 py-4">COT Billing Contracts</h1>
        </div>
      </div>
      <COTtopTabs tabs={monthTabs} />
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">

          {/* top header */}
          <div className="sm:flex sm:items-center py-6">
            <div className="flex w-full items-center mt-5 sm:mt-0">
              <NavLink to="/new_cot/new">
                <button
                  type="button"
                  className="
                    inline-flex items-center px-4 py-2 mr-3 border border-transparent
                    text-sm font-medium rounded-md shadow-sm text-white
                    bg-blue-700 hover:bg-blue-500 focus:outline-none
                    focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700">
                  New Contract
                </button>
              </NavLink>

              <div className="flex-grow">
                <SearchBarCP search={searchTerm} handleChange={handleSearchChange} onClick={handleSearchClick} />
              </div>
            </div>
          </div>

          <div className="inline-block min-w-full py-2 align-middle">
            {loading ? (
              <LoadingSpinner>Loading...</LoadingSpinner>
            ) : (
              <div className="inline-block min-w-full py-2 align-middle">
                {loading ? (
                  <LoadingSpinner>Loading...</LoadingSpinner>
                ) : (
                  <div className="shadow ring-1 ring-black ring-opacity-5">
                    <table className="min-w-full border-separate border-spacing-0">
                      <thead className="top-0 z-30 bg-white">
                        <tr>
                          {/* 1) ID/Edit */}
                          <th
                            className="border-b border-gray-300 bg-white py-3.5 pl-4 pr-3 
                                      text-left text-sm font-semibold text-gray-900"
                          >
                            ID/Edit
                          </th>

                          {/* 2) Account# | Signed Date */}
                          <th
                            className="border-b border-gray-300 bg-white px-3 py-3.5
                                      text-left text-sm font-semibold text-gray-900"
                          >
                            Account# | Signed Date
                          </th>

                          {/* 3) Address */}
                          <th
                            className="border-b border-gray-300 bg-white px-3 py-3.5
                                      text-left text-sm font-semibold text-gray-900"
                          >
                            Customer | Premise 
                          </th>

                          {/* 4) Sold By */}
                          <th
                            className="border-b border-gray-300 bg-white px-3 py-3.5
                                      text-left text-sm font-semibold text-gray-900"
                          >
                            Sold By
                          </th>

                          {/* 5) Contract Price */}
                          <th
                            className="border-b border-gray-300 bg-white px-3 py-3.5
                                      text-left text-sm font-semibold text-gray-900"
                          >
                            Contract Price
                          </th>

                          {/* 6) File */}
                          <th
                            className="border-b border-gray-300 bg-white px-3 py-3.5
                                      text-left text-sm font-semibold text-gray-900"
                          >
                            File
                          </th>

                          {/* 7) Next Due Date */}
                          <th
                            className="border-b border-gray-300 bg-white px-3 py-3.5
                                      text-left text-sm font-semibold text-gray-900"
                          >
                            Next Due
                          </th>

                          {/* 8) Notes */}
                          <th
                            className="border-b border-gray-300 bg-white px-3 py-3.5
                                      text-left text-sm font-semibold text-gray-900"
                          >
                            Notes
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {filteredContracts.map((contract, index) => (
                          <tr
                            key={contract.id}
                            className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'} divide-x`}
                          >
                            {/* 1) ID/Edit */}
                            <td
                              className={classNames(
                                index !== filteredContracts.length - 1 ? 'border-b border-gray-200' : '',
                                'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900'
                              )}
                            >
                              <SubMenu
                                label={contract.id}
                                idItem={contract.id}
                                links={getLinksForContract(contract)}
                              />
                              <div><Badge color={contract.status ? 'green' : 'yellow'} feed={contract.status ? 'Online' : 'Offline'} /></div>

                            </td>

                            {/* 2) Account # | Signed Date */}
                            <td className="px-3 py-4 text-sm text-gray-700">
                              <span className="font-semibold">
                                <NavLink to={`/cot_details/${contract.id}`}>
                                {contract.account_number} :
                                </NavLink>
                              <span className='text-gray-400 ml-1'>
                                 {contract.password}
                              </span>
                              </span>
                              <br />
                              <span className="text-blue-600">
                                {contract.date_signed && <DateFormat date={contract.date_signed} />}
                              </span>
                            </td>

                            {/* 3) Address (combined) */}
                            <td className="px-3 py-4 text-sm text-gray-700">
                              {/* Customer Name on first line */}
                              <span className="font-semibold text-black">
                                {contract.customer_name}
                              </span>
                              <br />
                              
                              {/* Contract Address on second line */}
                              <span className="font-semibold text-green-700">
                                {contract.contract_address}
                              </span>
                              <br />
                              
                              {/* City, State, Zip on third line */}
                              <span className="text-xs">
                                {contract.contract_city}, {contract.contract_state} {contract.contract_zip}
                              </span>
                            </td>

                            {/* 4) Sold By */}
                            <td className="px-3 py-4 text-sm text-gray-700">
                              {contract.sold_by}
                            </td>

                            {/* 5) Contract Price */}
                            <td className="px-3 py-4 text-sm text-gray-700">
                              <CurrencyFormat value={contract.contract_price} />
                            </td>

                            {/* 6) File */}
                            <td className="px-3 py-4 text-sm text-gray-700">
                              {contract.files && (
                                <>
                                  {contract.files.map((file, index) => (
                                   <div key={index}
                                        className={`flex items-center font-extrabold ${
                                          file.type === 'TB60'
                                            ? 'text-blue-600'
                                            : file.type === 'CA'
                                            ? 'text-green-600'
                                            : file.type === 'CO'
                                            ? 'text-red-600'
                                            : ''
                                        }`} >
                                        {file.name}
                                      <span className="mb-1">
                                        <FileLocation expFile={file.file_name} where="cot_file_contract" />
                                      </span>
                                      <span>{file.type}</span>
                                    </div>
                                  ))} 
                                </>
                              )}
                            </td>

                            {/* 7) Next Due Date => e.g. exp_date or logic */}
                            <td className="px-3 py-4 text-sm text-gray-700">
                              {contract.next_due_date && <DateFormat date={contract.next_due_date} />}
                            </td>

                            {/* 8) Notes */}
                            <td className="px-3 py-4 text-sm text-gray-700">
                              {contract.notes && (
                                <Truncate
                                  text={contract.notes}
                                  handleNotesView={() => handleNotesView(contract.id)}
                                />
                              )}

                              {notesIdOpen === contract.id && (
                                <ModalWindow
                                  job={contract.notes}
                                  closeNotes={() => setNotesIdOpen(null)}
                                  activeButton={true}
                                />
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* File Upload Modal */}
      <CotFileUploadModal
        isOpen={isModalFileOpen}
        onClose={() => setIsModalFileOpen(false)}
        onSubmit={handleUploadFile}
        onFileChange={handleFileChange}
        accept="application/pdf, image/png, image/jpeg" // Accept multiple file types
        title="Upload Document File"
        label="Select a file (PDF, PNG, JPEG) (Max size 2MB)"
      />
    </div>
  );
}

export default CotBillingPage;