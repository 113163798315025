import React, { useState, useEffect } from 'react';
import HeadingCp from '../components/HeadingCp';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { set } from 'date-fns';
import PhoneNumber from '../components/PhoneNumber';
import DateFormat from '../components/DateFormat';
import Badge from '../components/Badge';
import CurrencyFormat from '../components/CurrencyFormat';


function CotDetails() {
  const [cotDetail, setCotDetail] = useState([]);
  const [cotContact, setCotContact] = useState([]);
  const [buildingData, setBuildingData] = useState([]);
  const [system, setSystem] = useState([]);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    const fetchDetails = async () => {
      try {
        const response = await axios.get(`${apiUrl}/cot_crud.php?getDetails=true&&id=${id}`, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        });
        console.log(response.data);
        
        if(response.data[0] === 'success') {
          setCotDetail(response.data.contract || []);
          setCotContact(response.data.contacts || []);
          setBuildingData(response.data.building || []);
          setSystem(response.data.system || []);
        } else {
          toast.error("Error fetching contract details", { position: "top-center" });
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", { position: "top-center" });
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you don't have access to this area. Speak to the CFO.", { position: "top-center" });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [apiUrl, navigate, id]);


    return (
      <div className="min-h-screen bg-gray-100 p-6 flex flex-col items-center">
        {/* Container */}
        <div className="w-full max-w-5xl bg-white shadow-md rounded p-6">
          <HeadingCp label="Contract Details" />
        
        <a href="/cot_billing" className="text-sm font-semibold leading-6 text-blue-700">
          <span aria-hidden="true">&larr;</span> Back to List
        </a>        

          {/* CONTRACT INFO */}
          <section className="mb-8">
            <h2 className="text-md font-bold text-blue-900 mb-4 border-b pb-1">
              Contract Information
            </h2>
            <div className="grid grid-cols-3 md:grid-cols-3 gap-4">
              <div>
                <label className="text-sm text-gray-500">ID | Account Number</label>
                <p className="text-gray-800 font-medium">
                {cotDetail.customer_id || '[No ID]'} | {cotDetail.account_number || '[N/A]'}
                </p>
              </div>

              <div>
                <label className="text-sm text-gray-500">Date Signed</label>
                <p className="text-gray-800 font-medium">
                  {/* {cotDetail.date_signed || '[N/A]'} */}
                  {cotDetail.date_signed && <DateFormat date={cotDetail.date_signed} />}
                </p>
              </div>              
              
              <div>
                <label className="text-sm text-gray-500">Premise Address</label>
                <p className="text-gray-800 font-medium">
                  {cotDetail.contract_address || '[N/A]'}
                </p>
                <p className="text-gray-800 font-medium">
                  {cotDetail.contract_city}, {cotDetail.contract_state}{' '}
                  {cotDetail.contract_zip}
                </p>
              </div>          

              <div>
                <label className="text-sm text-gray-500">Auto Renew</label>
                <p className="text-gray-800 font-medium">
                  {cotDetail.auto_renew === '1' ? 
                    <span className='text-green-600'>Yes</span> : 
                    <span className='text-red-600'>No</span>
                  }
                </p>
              </div>

              <div>
                <label className="text-sm text-gray-500">Expiration Date</label>
                <p className="text-gray-800 font-medium">
                  {cotDetail.exp_date && <DateFormat date={cotDetail.exp_date} />}
                </p>
              </div>



              <div>
                <label className="text-sm text-gray-500">Sold By</label>
                <p className="text-green-700 font-bold">
                  {cotDetail.sold_by || '[N/A]'}
                </p>
              </div>            

              <div>
                <label className="text-sm text-gray-500">Password</label>
                <p className="text-gray-800 font-medium">
                  {cotDetail.password || '[N/A]'}
                </p>
              </div>

              <div>
                <label className="text-sm text-gray-500">Status (Active / etc.)</label>
                <p className="text-gray-800 font-medium">
                  {/* {cotDetail.status || '[N/A]'} */}
                  {cotDetail.status === '1' ? 
                  <Badge feed={"Active"} color="green" /> :
                  <Badge feed={"Inactive"} color="indigo" /> } 
                </p>
              </div>

              <div>
                <label className="text-sm text-gray-500">Activation Date</label>
                <p className="text-gray-800 font-medium">
                  {cotDetail.activation_date && <DateFormat date={cotDetail.activation_date} />}
                </p>
              </div>

              <div>
                <label className="text-sm text-gray-500">Frequency</label>
                <p className="text-gray-800 font-medium">
                  {cotDetail.frequency}
                </p>
              </div>

              <div>
                <label className="text-sm text-gray-500">Contract Price</label>
                <p className="text-gray-800 font-medium">
                  {/* {cotDetail.contract_price || '[N/A]'} */}
                  {cotDetail.contract_price && (<><CurrencyFormat value={cotDetail.contract_price}/></>)}
                </p>
              </div>

            </div>
            {/* Notes */}
            <div className="mt-4">
              <label className="text-sm text-gray-500">Notes</label>
              <p className="text-gray-800 font-medium whitespace-pre-wrap">
                {cotDetail.notes || ''}
              </p>
            </div>
          </section>

          {/* CONTACT INFO */}
          <section className="mb-8">
            <h2 className="text-md font-bold text-blue-900 mb-4 border-b pb-1">
              Contact Information
            </h2>

            {/* Primary */}
            <div className="mb-4 p-4 border border-gray-200 rounded">
              <h3 className="font-semibold text-gray-700 mb-2">Primary Contact</h3>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                <div>
                  <label className="text-sm text-gray-500">Name</label>
                  <p className="text-gray-800 font-medium">
                    {cotContact.contact_name_p || ''}
                  </p>
                </div>
                <div>
                  <label className="text-sm text-gray-500">Phone</label>
                  <p className="text-gray-800 font-medium">
                    {cotContact.contact_phone_p ? (
                      <PhoneNumber number={cotContact.contact_phone_p} />
                    ) : ''}
                  </p>
                </div>
                <div>
                  <label className="text-sm text-gray-500">Email</label>
                  <p className="text-gray-800 font-medium">
                    {cotContact.contact_email_p || ''}
                  </p>
                </div>
              </div>
            </div>

            {/* Secondary */}
            <div className="mb-4 p-4 border border-gray-200 rounded">
              <h3 className="font-semibold text-gray-700 mb-2">Secondary Contact</h3>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                <div>
                  <label className="text-sm text-gray-500">Name</label>
                  <p className="text-gray-800 font-medium">
                    {cotContact.contact_name_s || ''}
                  </p>
                </div>
                <div>
                  <label className="text-sm text-gray-500">Phone</label>
                  <p className="text-gray-800 font-medium">
                    {cotContact.contact_phone_s ? (
                      <PhoneNumber number={cotContact.contact_phone_s} />
                    ) : ''}
                  </p>
                </div>
                <div>
                  <label className="text-sm text-gray-500">Email</label>
                  <p className="text-gray-800 font-medium">
                    {cotContact.contact_email_s || ''}
                  </p>
                </div>
              </div>
            </div>

            {/* Tertiary */}
            <div className="mb-4 p-4 border border-gray-200 rounded">
              <h3 className="font-semibold text-gray-700 mb-2">Tertiary Contact</h3>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                <div>
                  <label className="text-sm text-gray-500">Name</label>
                  <p className="text-gray-800 font-medium">
                    {cotContact.contact_name_t || ''}
                  </p>
                </div>
                <div>
                  <label className="text-sm text-gray-500">Phone</label>
                  <p className="text-gray-800 font-medium">
                    {cotContact.contact_phone_t ? (
                      <PhoneNumber number={cotContact.contact_phone_t} />
                    ) : ''}
                  </p>
                </div>
                <div>
                  <label className="text-sm text-gray-500">Email</label>
                  <p className="text-gray-800 font-medium">
                    {cotContact.contact_email_t || ''}
                  </p>
                </div>
              </div>
            </div>

            {/* Fourth */}
            <div className="mb-4 p-4 border border-gray-200 rounded">
              <h3 className="font-semibold text-gray-700 mb-2">Fourth Contact</h3>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                <div>
                  <label className="text-sm text-gray-500">Name</label>
                  <p className="text-gray-800 font-medium">
                    {cotContact.contact_name_f || ''}
                  </p>
                </div>
                <div>
                  <label className="text-sm text-gray-500">Phone</label>
                  <p className="text-gray-800 font-medium">
                    {cotContact.contact_phone_f ? (
                      <PhoneNumber number={cotContact.contact_phone_f} />
                    ) : ''}
                  </p>
                </div>
                <div>
                  <label className="text-sm text-gray-500">Email</label>
                  <p className="text-gray-800 font-medium">
                    {cotContact.contact_email_f || ''}
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/* BUILDING INFO */}
          <section className="mb-4">
            <h2 className="text-md font-bold text-blue-900 mb-4 border-b pb-1">
              Building Information
            </h2>
            <div className="grid grid-cols-3 md:grid-cols-3 gap-6">
              <div>
                <label className="text-sm text-gray-500">BIN Number</label>
                <p className="text-gray-800 font-medium">
                  {buildingData.BIN_number}
                </p>
              </div>
              <div>
                <label className="text-sm text-gray-500">Building Classification</label>
                <p className="text-gray-800 font-medium">
                  {buildingData.building_classification}
                </p>
              </div>
              <div>
                <label className="text-sm text-gray-500">BDOC</label>
                <p className="text-gray-800 font-medium">
                  {buildingData.bdoc}
                </p>
              </div>
              <div>
                <label className="text-sm text-gray-500">FA 14 Submitted</label>
                <p className="text-gray-800 font-medium">
                  {buildingData.fa_14_submitted === '1' ? 'Yes' : 'No'}
                </p>
              </div>
              <div>
                <label className="text-sm text-gray-500">FDNY Inspection Date</label>
                <p className="text-gray-800 font-medium">
                  {/* {buildingData.fdny_inspection_date} */}
                  {buildingData.fdny_inspection_date && (
                    <DateFormat date={buildingData.fdny_inspection_date} />
                  )}
                </p>
              </div>
              <div>
                <label className="text-sm text-gray-500">Date LOA</label>
                <p className="text-gray-800 font-medium">
                  {buildingData.date_loa && (
                    <DateFormat date={buildingData.date_loa} />
                    
                  )}
                </p>
              </div>
              <div>
                <label className="text-sm text-gray-500">Base Building LOA</label>
                <p className="text-gray-800 font-medium">
                  {buildingData.base_building_loa === '1' ? 'Yes' : <span className='text-red-600'>No</span>}
                </p>
              </div>
              <div>
                <label className="text-sm text-gray-500">Height</label>
                <p className="text-gray-800 font-medium">
                  {buildingData.height}
                </p>
              </div>
              <div>
                <label className="text-sm text-gray-500">Floors</label>
                <p className="text-gray-800 font-medium">
                  {buildingData.floors}
                </p>
              </div>
              <div>
                <label className="text-sm text-gray-500">Floor Area</label>
                <p className="text-gray-800 font-medium">
                  {buildingData.floor_area}(SF)
                </p>
              </div>
            </div>

            {/* if you have system_type, manufacture, panel_location */}
            <div className="mt-6 grid grid-cols-3 md:grid-cols-3 gap-6">
              <div>
                <label className="text-sm text-gray-500">System Type</label>
                <p className="text-gray-800 font-medium">
                  {buildingData.system_type}
                </p>
              </div>
              <div>
                <label className="text-sm text-gray-500">Manufacture</label>
                <p className="text-gray-800 font-medium">
                  {buildingData.system_manufacture}
                </p>
              </div>
              <div>
                <label className="text-sm text-gray-500">Panel Location</label>
                <p className="text-gray-800 font-medium">
                  {buildingData.system_panel_location}
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }

export default CotDetails;
