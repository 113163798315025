import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchBarCP from '../components/SearchBarCP';
import LegendCP from '../components/LegendCP';
import SubMenu from '../components/SubMenu';
import ModalWindow from '../components/ModalWindow';
import Truncate from '../components/Truncate';
import DateFormat from '../components/DateFormat';
// import { userData } from '../context/UserData';
import LoadingSpinner from '../components/LoadingSpinner';
import HeadingCp from '../components/HeadingCp';
import CurrencyFormat from '../components/CurrencyFormat';
import FileLocation from '../components/FileLocation';
import { PencilSquareIcon, PlusIcon } from '@heroicons/react/20/solid';
import MonthTabs from '../components/MonthTabs';
import Badge from '../components/Badge';
import ConfirmDeleteModal from '../components/ConfirmDeleteModal';


const ContractsPage = () => {
  const navigate = useNavigate();
  const [contracts, setContracts] = useState([]);
  const [filteredContracts, setFilteredContracts] = useState([]);
  const [showActive, setShowActive] = useState(true);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [openModalId, setOpenModalId] = useState(null);
  const [notesIdOpen, setNotesIdOpen] = useState(null);
  // const userData = useUserData();
  const [pendingShifts, setPendingShifts] = useState([]);
  const [showShiftPrompt, setShowShiftPrompt] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/contracts_crud.php`;
  const [inspectionContractId, setInspectionContractId] = useState(null);
  const [showInspectionModal, setShowInspectionModal] = useState(false);
  const months = [
      { name: 'All', value: 'all' },
      { name: 'January', value: '01' },
      { name: 'February', value: '02' },
      { name: 'March', value: '03' },
      { name: 'April', value: '04' },
      { name: 'May', value: '05' },
      { name: 'June', value: '06' },
      { name: 'July', value: '07' },
      { name: 'August', value: '08' },
      { name: 'September', value: '09' },
      { name: 'October', value: '10' },
      { name: 'November', value: '11' },
      { name: 'December', value: '12' },
  ];
  const handleSchedulerClick = () => {
    navigate('/inspection_calendar', { state: { contracts, apiUrl } });
  };
  const currentYear = new Date().getFullYear();
  const [currentMonth, setCurrentMonth] = useState(() => {
  // Initialize to the current month
    const date = new Date();
      return String(date.getMonth() + 1).padStart(2, '0');
  });
  const [showActualHrsModal, setShowActualHrsModal] = useState(false);
  const [actualHrsContractId, setActualHrsContractId] = useState(null);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [contractToDeactivate, setContractToDeactivate] = useState(null);
  const [file, setFile] = useState(null);
  const [selectGenFile, setSelectGenFile] = useState(null);
  const [isModalFileGenOpen, setIsModalFileGenOpen] = useState(false);

  // This function opens the confirm modal
  const handleDeactivateClick = (contractId, currentStatus) => {
    setShowConfirmModal(true);
    // store the contract ID and its current status in state
    setContractToDeactivate({ id: contractId, status: currentStatus });
  };

    // When user actually confirms "Deactivate" in the modal
  const handleConfirmDeactivate = () => {
    if (contractToDeactivate) {
      voidContractSubmit(contractToDeactivate.id, contractToDeactivate.status);
    }
    setShowConfirmModal(false);
    setContractToDeactivate(null);
  };

    // When user cancels
  const handleCancelDeactivate = () => {
    setShowConfirmModal(false);
    setContractToDeactivate(null);
  };

  useEffect(() => {
    fetchDueContracts();
    fetchPendingShifts();
  }, []);

  const handleMonthChange = (monthValue) => {
    setCurrentMonth(monthValue);
    fetchDueContracts(monthValue);
  };

  const fetchDueContracts = async (selectedMonth = currentMonth, selectedYear = currentYear) => {
  setLoading(true);
  try {
    const response = await axios.get(api, {
      params: {
        getDueContracts: 'getDueContracts',
        month: selectedMonth,
        year: selectedYear,
      },
    });
    if (response.data) {
      setContracts(response.data);
      setFilteredContracts(response.data);
    } else {
      toast.error('Error fetching due contracts', { position: 'top-center' });
    }
    } catch (error) { 
        // Handle errors as in your existing code
        if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", { position: "top-center" });
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! You don't have access to this area. Speak to the CFO.", { position: "top-center" });
        } else {
          toast.error("Error fetching due contracts", { position: 'top-center' });
        }
      } finally {
        setLoading(false);
      }
  };

  const fetchPendingShifts = async () => {
    try {
      const response = await axios.get(api, { params: { getPendingShift: 'getPendingShift' } });
      if (response.data && response.data.length > 0) {
        setPendingShifts(response.data);
        setShowShiftPrompt(true);
      }
    } catch (error) {
      console.error('Error fetching pending shifts', error);
    }
  };

  const handleShiftDueDates = async (contractId) => {
    try {
      const response = await axios.post(api, {
        status: 'shiftDueDates',
        data: { contract_id: contractId }
      }, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data.success) {
        toast.success('Due dates shifted successfully', { position: 'top-center' });
        // Refresh the data
        fetchDueContracts();
        fetchPendingShifts();
      } else {
        toast.error(response.data.error || 'Error shifting due dates', { position: 'top-center' });
      }
    } catch (error) {
      console.error('Error shifting due dates', error);
      toast.error('Error shifting due dates', { position: 'top-center' });
    }
  };

  const handleKeepOriginalSchedule = (contractId) => {
    // Optionally, update the contract to not prompt again
    setPendingShifts(prev => prev.filter(contract => contract.id !== contractId));
    if (pendingShifts.length === 1) {
      setShowShiftPrompt(false);
    }
  };

  const handleRecordInspection = (contractId) => {
    setInspectionContractId(contractId);
    setShowInspectionModal(true);
  };  

  const submitInspection = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const scheduled_due_date = formData.get('scheduled_due_date');
    const actual_inspection_date = formData.get('actual_inspection_date');
    const technician_id = formData.get('technician_id') || null;

    try {
      const response = await axios.post(api, {
        status: 'recordInspection',
        data: {
          contract_id: inspectionContractId,
          scheduled_due_date,
          actual_inspection_date,
          technician_id
        }
      }, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data.success) {
        toast.success('Inspection recorded successfully', { position: 'top-center' });
        // Close the modal and refresh data
        setShowInspectionModal(false);
        fetchDueContracts();
        fetchPendingShifts();
      } else if (response.data.warning) {
        toast.warning(response.data.warning, { position: 'top-center' });
        // Possibly show the shift prompt
        fetchPendingShifts();
      } else {
        toast.error(response.data.error || 'Error recording inspection', { position: 'top-center' });
      }
    } catch (error) {
      console.error('Error recording inspection', error);
      toast.error('Error recording inspection', { position: 'top-center' });
    }
  };
  
  const toggleShowVoid = () => {
    setShowActive(!showActive);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value === '') {
      setFilteredContracts(contracts);
    } else {
      const filtered = contracts.filter(contract =>
        (contract.job_site_address && contract.job_site_address.toLowerCase().includes(value.toLowerCase())) ||
        (contract.customer_name && contract.customer_name.toLowerCase().includes(value.toLowerCase())) ||
        (contract.contract_type && contract.contract_type.toLowerCase().includes(value.toLowerCase()))
      );
      setFilteredContracts(filtered);
    }
  };

  const handleSearchClick = async () => {
    setLoading(true);
    const bodyData = {
      status: 'search',
      data: { search: searchTerm }
    };

    try {
      const response = await axios.post(api, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data && Array.isArray(response.data)) {
        setContracts(response.data);
        setFilteredContracts(response.data);
      } else {
        setFilteredContracts([]);
        toast.error('No results found for: ' + searchTerm, { position: 'top-center' });
      }
    } catch (error) {
      console.error('Error searching contracts', error);
      toast.error('Error searching contracts', { position: 'top-center' });
    } finally {
      setLoading(false);
    }
  };

  const handleNotes = (contractId) => {
    setOpenModalId(contractId);
  };

  const handleChange = (event, id, user) => {
    const { name, value } = event.target;
    setFilteredContracts(prev => prev.map(contract => {
      if (parseInt(contract.id) === id) {
        const updatedContract = { ...contract, [name]: value };
        updatedContract.employee_name = `${user.first_name} ${user.last_name}`;
        return updatedContract;
      } else {
        return contract;
      }
    }));
  };

  const handleComplete = async (id) => {
    try {
      await axios.post(api, { status: 'submit_complete', id }, {
        headers: { 'Content-Type': 'application/json' }
      });

      toast.info('Contract marked as complete!', { position: 'top-center' });
      // Update the contract's status locally
      setFilteredContracts(prev => prev.map(contract => {
        if (contract.id === id) {
          return { ...contract, status: 'complete' };
        } else {
          return contract;
        }
      }));
    } catch (error) {
      console.error('Error updating contract', error);
      toast.error('Error updating contract', { position: 'top-center' });
    }
  };

  const voidContractSubmit = async (id, currentStatus) => {
    setLoading(true);
    const newStatusActive = currentStatus === 1 ? 0 : 1;
    const bodyData = {
      status: 'active',
      data: { contract_id: id, status: newStatusActive }
    };
    try {
      const response = await axios.post(api, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data.success) {
        setFilteredContracts(prev => prev.map(contract => {
          if (contract.id === id) {
            return { ...contract, status: newStatusActive };
          }
          return contract;
        }));
        toast.success('Contract status updated!', { position: 'top-center' });
      } else {
        toast.error('Error updating contract', { position: 'top-center' });
      }
    } catch (error) {
        if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
        position: "top-center"
        });
        navigate('/login');
        } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
        position: "top-center"
        });
        }
    } finally {
      setLoading(false);
    }
  };

  // 2) Handler to open the modal
  const handleActualHrsClick = (contractId) => {
    setActualHrsContractId(contractId);
    setShowActualHrsModal(true);
  };

  // 3) Form submission
  const submitActualHrs = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const enteredHrs = formData.get('actual_hours');

    try {
      const response = await axios.post(api, {
        status: 'updateActualHours',
        data: {
          contract_id: actualHrsContractId,
          actual_hours: enteredHrs
        }
      }, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data.success) {
        toast.success('Actual hours updated!', { position: 'top-center' });
        fetchDueContracts();
      } else {
        toast.error(response.data.error || 'Error updating actual hours', { position: 'top-center' });
      }
    } catch (error) {
      console.error('Error updating actual hrs', error);
      toast.error('Error updating actual hours', { position: 'top-center' });
    } finally {
      setShowActualHrsModal(false);
      setActualHrsContractId(null);
    }
  };

  // 4) getLinksForContract updated to open the new modal
  const getLinksForContract = (contract) => [
    {
      name: 'Edit Contract',
      click: () => navigate(`/maintenance_contract/edit/${contract?.id}`)
    },
    {
      name: 'Update Actual Hrs',
      click: () => handleActualHrsClick(contract.id)
    },
    { name: 'Upload File', click: () => {setIsModalFileGenOpen(true); setSelectGenFile(contract.id); }, key: `void-${contract.id}` },
    {
      name: contract.status === 1 ? 'Deactivate Contract' : 'Activate Contract',
      click: () => handleDeactivateClick(contract.id, contract.status)
    },
  ];

  function DateFormat({ date }) {
    if (!date) {
      // If it's empty or null, show fallback text
      return <span>No Date</span>;
    }

    const parsed = new Date(date);
    if (Number.isNaN(parsed.getTime())) {
      // Means "Invalid Date"
      return <span>Invalid Date</span>;
    }

    // If valid, display it
    return <span>{parsed.toLocaleDateString()}</span>;
  }

  //Handler to open the file upload modal
  const handleGenUploadFile = async (e) => {
    e.preventDefault();
    if (!file) {
      toast.error("Please select a file before uploading.", { position: 'top-center' });
      return;
    }
    
    const formData = new FormData();
    formData.append('status', 'submit_gen_file');
    formData.append('id', selectGenFile); // Contract ID
    formData.append('file', file);
    


    try {
      const response = await axios.post(`${apiUrl}/maintenance_contract_file.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      if (response.data.success) {
        toast.info('File uploaded successfully!', { position: 'top-center' });

        // Update the state with the new file information
        setFilteredContracts(prevItems =>
        prevItems.map(item => {
          return item.contract_details.id === selectGenFile ? { ...item, contract_details:{...item.contract_details, file: response.data[0]}}: item 
        } ));

        setFile(null);
        setIsModalFileGenOpen(false);
      } else {
        toast.error(response.data.error || "Error uploading file", { position: 'top-center' });
      }
    } catch (error) {
      toast.error("An error occurred during file upload.", { position: 'top-center' });
      //console.error('Error uploading file', error);
    }
  };

  //handle file change
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      toast.error("No file selected.", { position: 'top-center' });
      setFile(null);
      return;
    }

    const validFileTypes = ['application/pdf'];
    const maxSize = 2 * 1024 * 1024; // 2MB in bytes

    if (!validFileTypes.includes(selectedFile.type)) {
      toast.error("Invalid file type. Only PDF is allowed.", { position: 'top-center' });
      setFile(null);
      return;
    }

    if (selectedFile.size > maxSize) {
      toast.error("File size exceeds 2MB.", { position: 'top-center' });
      setFile(null);
      return;
    }

    setFile(selectedFile);
  };  

  return (
    <div className="mx-auto max-w-[1250px] px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-2xl font-semibold text-gray-500 mb-8">
        <HeadingCp label="Maintenance Dashboard" />
      </h1>

      <LegendCP color="fill-red-300" label="Void" />
      <LegendCP color="fill-blue-600" label="Due Date Shifted" />
      <LegendCP color="fill-red-600" label="Missing Due (ID)" />
      <br />

      {/* Include the MonthTabs component */}
      <MonthTabs
        months={months}
        currentMonth={currentMonth}
        onMonthChange={handleMonthChange}
      />    
      <br />
      <br />
      <div className="flex space-x-3 items-center mb-10">
        <button
          onClick={() => navigate(`/maintenance_contract/new/new`)}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium 
                    rounded-md shadow-sm text-white bg-orange-400 hover:bg-orange-300 focus:outline-none 
                    focus:ring-2 focus:ring-offset-2 focus:ring-black-500"
        >
          <PlusIcon className="h-5" /> New
        </button>

        <button
          onClick={() => navigate(`/inspection_active`)}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium 
                    rounded-md shadow-sm text-white bg-red-600 hover:bg-red-400 focus:outline-none 
                    focus:ring-2 focus:ring-offset-2 focus:ring-black-500"
        >
          Show Inactivate
        </button>

        <button
          onClick={handleSchedulerClick}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium 
                    rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-400 focus:outline-none 
                    focus:ring-2 focus:ring-offset-2 focus:ring-black-500"
        >
          Scheduler
        </button>

        <button
            onClick={() => navigate(`/maintenance_inspections`)}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium 
                      rounded-md shadow-sm text-white bg-green-600 hover:bg-green-400 focus:outline-none 
                      focus:ring-2 focus:ring-offset-2 focus:ring-black-500">
            Inspections
        </button>      

        <SearchBarCP search={searchTerm} handleChange={handleSearchChange} onClick={handleSearchClick} />
      </div>

      {loading ? (
        <LoadingSpinner>Loading...</LoadingSpinner>
      ) : (
            <table className="min-w-full divide-y divide-gray-300 bg-gray-200 border rounded-lg">
                <thead>
                  <tr className="divide-x divide-gray-400">
                    {[
                      'ID/Edit',
                      'Job-Site/Customer',
                      'PF',
                      'System Type',
                      'Current Price',
                      'Contact',
                      'N/F',
                      'Signed | Exp.',
                      'NI|LI',
                      'Due Hrs | Acutal Hrs',
                      ].map((header, index) => (
                        <th key={index} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          {header}
                        </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                 {filteredContracts.map((contract, index) => {
                      const isOverdue = new Date(contract.next_due_date) < new Date();
                      const rowClassName = () => {
                          if (isOverdue) {
                            return 'bg-red-100 divide-x';
                          } else if (contract.contract_details.due_dates_shifted == 1) {
                            return 'bg-blue-100 divide-x';
                          } else {
                            return `${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'} divide-x`;
                          }
                      };

                    return (
                      <tr key={contract.maintenance_id} className={rowClassName()}>
                      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-700 sm:w-auto sm:max-w-none sm:pl-2">
                        {contract.contract_details.status === 0 && (
                          <Badge color={'yellow'} feed={'NA'} />
                        )} 
                        <SubMenu label={contract.maintenance_id} 
                                links={getLinksForContract(contract.contract_details)} 
                                idItem={contract.maintenance_id} />
                        <span className="text-gray-400 text-xs">{contract.contract_details.contract_type}</span>
                      </td>

                      {/* job site address and customer name */}
                      <td className="px-3 py-4 text-sm text-gray-500">
                          <span className='font-bold text-black'>
                              {contract.contract_details.job_site_address}
                              {contract.contract_details.job_site_city && (' '+ contract.contract_details.job_site_city + ', ')} 
                              {contract.contract_details.job_site_state && (' '+ contract.contract_details.job_site_state + ' ')} 
                              {contract.contract_details.job_site_zip && (' '+ contract.contract_details.job_site_zip)} 
                          </span>
                        <p className="text-sm text-gray-400">{contract.customer_name}</p>
                      </td>

                      {/* frequency */}
                      <td className="px-3 py-4 text-sm text-gray-500">
                        {contract.contract_details.frequency === 1
                          ? '(A)'
                          : contract.contract_details.frequency === 3
                          ? '(Q)'
                          : contract.contract_details.frequency === 12
                          ? '(M)'
                          : contract.contract_details.frequency === 6
                          ? '(SA)'
                          : ''}
                        <span className="pr-1">{contract.start_year}</span>
                        <span>{contract.term_begins}</span>
                      </td>
                      
                      {/* system type */}
                      <td className="px-3 py-4 text-sm text-gray-500">
                        {contract.contract_details.system_type}
                      </td>
                      
                      {/* current price */}
                      <td className="px-3 py-4 text-sm text-gray-500">
                        {contract.contract_details.current_price ? <CurrencyFormat value={contract.contract_details.current_price} /> : 'N/A'}
                      </td>
                      
                      {/* Contact */}
                      <td className="px-3 py-4 text-sm text-gray-500 max-w-[260px] whitespace-normal break-words">
                        <span className='text-green-700'>
                          {contract.contract_details.contact_name}
                          {contract.contract_details.contact_phone && (
                            <>
                              <br />
                              {contract.contract_details.contact_phone}
                            </>
                          )}
                          {contract.contract_details.contact_email && (
                            <>
                              <br />
                              {contract.contract_details.contact_email}
                            </>
                          )}
                        </span>
                      </td>

                      {/* notes / file */}
                      <td className="px-3 py-4 text-sm text-gray-500">
                        {contract.contract_details.notes && (
                          <span
                            className="hover:cursor-pointer"
                            onClick={() => handleNotes(contract.maintenance_id)}
                          >
                            <PencilSquareIcon className="h-5 text-blue-500" />
                          </span>
                        )}
                        {openModalId === contract.maintenance_id && (
                          <ModalWindow
                            job={<div>{contract.contract_details.notes}</div>}
                            closeNotes={() => setOpenModalId(null)}
                          />
                        )}
                        <br/>
                        <FileLocation expFile={contract.contract_details.file} where="maintenance_files" />
                      </td>

                      {/* signed and expiration date */}
                      <td className="px-3 py-4 text-sm text-gray-500">
                        <p><DateFormat date={contract.contract_details.signed_date}/></p>
                        {/* <p>{DateFormat(contract.contract_details.signed_date)}</p> */}
                        <p className="text-xs text-gray-400">
                          {contract.contract_details.auto_renew === 1 ? (
                            'Auto-renew'
                          ) : (
                                <span className='text-red-800'>
                                {/* Ex: {DateFormat(contract.contract_details.exp_date_contract)} */}
                                Ex: <DateFormat date={contract.contract_details.exp_date_contract}/>
                                </span>
                          )}
                        </p>
                      </td>
                      
                      {/* next due date / last inspection */}
                      <td className="px-3 py-4 text-gray-500 text-sm">
                        <span>
                          {contract.next_due_date ? 
                              // DateFormat(contract.next_due_date)
                              <DateFormat date={contract.next_due_date}/>
                            : 'N/A'}
                        </span>
                        <span className='text-green-700'>
                          {contract.contract_details.last_inspection_date && (
                            <div className={`${contract.contract_details.last_inspection_date? (
                              new Date(contract.contract_details.last_inspection_date).getMonth() === currentMonth 
                                  ? 'text-green-700 bg-rose-50 rounded p-1' : 'text-white bg-red-600 rounded p-1') 
                              : ('')}`}>
                            <DateFormat  date={contract.contract_details.last_inspection_date} />
                            </div>
                          )
                          }
                        </span>
                      </td>

                      {/* due hours / acutal hrs*/}
                      <td className="px-3 py-4 text-sm text-gray-500">
                        {contract.due_hours ? `${contract.due_hours} Hrs` : 'N/A'}
                        <p className='text-green-700'>{contract.contract_details.actual_hours ? `${contract.contract_details.actual_hours} AHrs` : 'N/A'}</p>
                      </td>
                    </tr>
                  );
                 })}
                </tbody>
            </table>      
      )}

      {showShiftPrompt && (
        <ModalWindow
          closeNotes={() => setShowShiftPrompt(false)}
          job={
            <div>
              <h2 className="text-lg font-semibold">Shift Due Dates</h2>
              {pendingShifts.map(contract => (
                <div key={contract.id} className="mb-4">
                  <p>
                    Contract <strong>{contract.id}</strong> for Customer <strong>{contract.customer_id}</strong> has an inspection completed late.
                  </p>
                  <p>
                    Scheduled Due Date: {DateFormat(contract.scheduled_due_date)}
                  </p>
                  <p>
                    Actual Inspection Date: {DateFormat (contract.actual_inspection_date)}
                  </p>
                  <button
                    onClick={() => handleShiftDueDates(contract.id)}
                    className="bg-blue-500 text-white px-4 py-2 rounded mt-2 mr-2"
                  >
                    Shift Due Dates
                  </button>
                  <button
                    onClick={() => handleKeepOriginalSchedule(contract.id)}
                    className="bg-gray-500 text-white px-4 py-2 rounded mt-2"
                  >
                    Keep Original Schedule
                  </button>
                </div>
              ))}
            </div>
          }
        />
      )}

      {showInspectionModal && (
        <ModalWindow
          closeNotes={() => setShowInspectionModal(false)}
          job={
            <div>
              <h2 className="text-lg font-semibold">Record Inspection for Contract {inspectionContractId}</h2>
              <form onSubmit={submitInspection}>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Scheduled Due Date:
                    <input type="date" name="scheduled_due_date" required className="mt-1 block w-full" />
                  </label>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Actual Inspection Date:
                    <input type="date" name="actual_inspection_date" required className="mt-1 block w-full" />
                  </label>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Technician ID:
                    <input type="number" name="technician_id" className="mt-1 block w-full" />
                  </label>
                </div>
                <div className="flex justify-end">
                  <button type="button" onClick={() => setShowInspectionModal(false)} className="mr-2 px-4 py-2 bg-gray-500 text-white rounded">
                    Cancel
                  </button>
                  <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          }
        />
      )}

      {/* 5) Render the new modal */}
      {showActualHrsModal && (
        <ModalWindow
          closeNotes={() => {
            setShowActualHrsModal(false);
            setActualHrsContractId(null);
          }}
          job={
            <div className="max-w-">
              <h2 className="text-lg font-semibold mb-4">
                Update Actual Hours For (Contract ID: {actualHrsContractId})
              </h2>
              <form onSubmit={submitActualHrs}>
                <div className="mb-4">
                  <label htmlFor="actual_hours" className="block text-sm font-medium text-gray-700">
                    Enter Actual Hours:
                  </label>
                  <input
                    type="number"
                    name="actual_hours"
                    id="actual_hours"
                    required
                    className="mt-1 block w-full border rounded p-1"
                  />
                </div>
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() => {
                      setShowActualHrsModal(false);
                      setActualHrsContractId(null);
                    }}
                    className="mr-2 px-4 py-2 bg-gray-500 text-white rounded"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-blue-500 text-white rounded"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          }
        />
      )}

      {/* file upload */}
      {isModalFileGenOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-600 bg-opacity-50">
          <div className="bg-white p-8 rounded-md shadow-md min-w-[500px]">
            <h2 className="text-xl font-semibold mb-4">Upload File</h2>
            <form onSubmit={handleGenUploadFile}>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Select a PDF file (Max size 2MB)</label>
                <input
                  type="file"
                  accept="application/pdf"
                  onChange={handleFileChange}
                  className="mt-2"
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setIsModalFileGenOpen(false)}
                  className="px-4 py-2 mr-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                >
                  Upload
                </button>
              </div>
            </form>
          </div>
        </div>
      )}      

      <ConfirmDeleteModal
        isOpen={showConfirmModal}
        onConfirm={handleConfirmDeactivate}
        onCancel={handleCancelDeactivate}
        title={contractToDeactivate?.status === 1 ? 'Confirm Deactivate' : 'Confirm Activate'}
        message="Are you sure you want to change this contract's status?"
        itemName={contractToDeactivate ? `Contract ID: ${contractToDeactivate.id}` : ''}/>      
    </div>
  );
};

export default ContractsPage;
