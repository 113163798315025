// pages/InspectionDetails.jsx
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

import InspectionType from './InspectionType';
import DevicesTested from './DevicesTested';
import TechNotes from './TechNotes';
import AdditionalTech from './AdditionalTech';
import LogBooks from './LogBooks';
import CustomerEmail from './CustomerEmail';
import HeadingCp from '../components/HeadingCp';
import Accordion from '../components/Accordion';

function InspectionDetails() {
  const { id: urlID } = useParams();
  const navigate = useNavigate();

  const [inspection, setInspection] = useState({});
  const [activeInspection, setActiveInspection] = useState(true);
  const [type, setType] = useState('');
  const [deviceList, setDeviceList] = useState({});
  const [inspectionTech, setInspectionTech] = useState({ techNotes: '' });
  const [selectedTech, setSelectedTech] = useState([]);         // Always start as an array
  const [techList, setTechList] = useState([]);
  const [logbookstatus, setLogbookStatus] = useState('');
  const [inspection_report, setInspectionReport] = useState({});
  const [activateSignature, setActivateSignature] = useState(false);
  const [panelList, setPanelList] = useState([]);
  const [ready, setReady] = useState(false);
  const [showDeviceBox, setShowDeviceBox] = useState(false);
  const [showLogBookBox, setShowLogBookBox] = useState(false);
  const [showCustomerEmailBox, setShowCustomerEmailBox] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchPanelList(urlID);
    fetchInspectionData(urlID);
    fetchDeviceList(urlID);
    fetchTechList(urlID);
  }, [urlID, ready]);

  // 1) Panel list
  const fetchPanelList = async (urlID) => {
    try {
      const response = await axios.get(`${apiUrl}/contracts_crud.php?getPanel=${urlID}`, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
      });
      if (response.data[0] === 'success') {
        setPanelList(response.data[1]);
      } else {
        console.error('Error getting panel list');
      }
    } catch (error) {
      console.error('Error fetching panel list:', error);
    }
  };

  // 2) Inspection Data
  const fetchInspectionData = async (urlID) => {
    try {
      const response = await axios.get(`${apiUrl}/contracts_crud.php?getInspectionDetails=${urlID}`, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
      });
      // console.log(response.data, 'inspection data');
      
      if (response.data[0] === 'success') {
        const data = response.data[1];
        setInspection(data);
        setActiveInspection(true);
        setInspectionTech({ techNotes: data.tech_notes });
        setLogbookStatus(data.status);

        // If there's a customer_notification_email, prepare for signature
        if (data.customer_notification_email) {
          setActivateSignature(true);
          setInspectionReport({
            customerPrint: data.customer_print,
            customerEmail: data.customer_notification_email
          });
        }

        setType(data.type);
        // Show/hide sections
        if (data.type) {
          setShowDeviceBox(true);
          // Possibly use data[3].status checks if needed
          // setShowCustomerEmailBox(...) if logic requires
        } else {
          setShowDeviceBox(false);
          setShowCustomerEmailBox(false);
        }
      } else {
        console.error('Error getting inspection data');
      }
    } catch (error) {
      console.error('Error fetching inspection data:', error);
    }
  };

  // 3) Device List
  const fetchDeviceList = async (urlID) => {
    try {
      const response = await axios.get(`${apiUrl}/contracts_crud.php?getDeviceList=${urlID}`, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
      });
      const data = response.data;
      if (data[0] === 'success') {
        setReady(data[2]);
        setDeviceList(data[1]);

        const logbook = data[4];
        // const checkListMainMissing = data[6]; // if needed

        if (ready) {
          setShowLogBookBox(true);
          if (logbook?.status) {
            setLogbookStatus(logbook.status);
            setShowCustomerEmailBox(true);
          }
        } else {
          setShowLogBookBox(false);
          setShowCustomerEmailBox(false);
        }
      } else {
        console.error('Error getting device list');
      }
    } catch (error) {
      console.error('Error fetching device list:', error);
    }
  };

  // 4) Tech List
  const fetchTechList = async (urlID) => {
    try {
      const response = await axios.get(`${apiUrl}/contracts_crud.php?getAdditionalTech=${urlID}`, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
      });
      // console.log(response.data, 'techList from fetch');

      if (response.data[0] === 'success') {
        setTechList(response.data[1]);
        
        // -- MAIN FIX: Ensure `selectedTech` is always an array --
        const possibleSelectedTech = response.data[2];
        if (Array.isArray(possibleSelectedTech)) {
          setSelectedTech(possibleSelectedTech);
        } else {
          // If it's not an array, fallback to empty array
          setSelectedTech([]);
          console.warn('selectedTech from server is not an array, defaulting to []');
        }
      } else {
        console.error('Error getting tech list');
      }
    } catch (error) {
      console.error('Error fetching tech list:', error);
    }
  };

  // Navigations
  const newInspection = (type) => {
    if (type === 'new') {
      navigate('/mdep_m_inspection/new');
    } else {
      navigate('/mdep_m_inspection/list');
    }
  };

  const signature = () => {
    navigate(`/t/tinspsignature/${urlID}`);
  };

  // Accordion toggles
  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="p-5">
      <HeadingCp label={"Maintenance Inspection Details"} />

      {activeInspection && (
        <div>
          <div className="flex flex-row p-2 bg-gray-100 border-1 border-black rounded-md mb-2 p-1">
            <div className="w-full">
              <span className="font-bold text-green-700">{inspection.customer_name}</span><br/>
              <p className='text-gray-600 text-sm'>
                {inspection.job_site_address}, {inspection.job_site_address2}
              </p> 
              <span className='text-gray-600 text-sm'>
                {inspection.city} {inspection.state} {inspection.zip_code}
              </span>
              <p className="text-sm">
                {inspection.system_type} :Due {inspection.scheduled_due_date} - {inspection.schedule_date_to}
              </p>
              <p className="text-sm">
                {inspection.contact_name} : {inspection.contact_phone}
              </p>
            </div>
          </div>

          <div>
            {/* 1) All Devices */}
            <Accordion
              title="All Devices Tested and Operational (Required)"
              content={
                <DevicesTested
                  deviceList={deviceList}
                  setDeviceList={setDeviceList}
                  urlID={urlID}
                  ready={ready}
                  setReady={setReady}
                  showLogBookBox={showLogBookBox}
                  setShowLogBookBox={setShowLogBookBox}
                  setShowCustomerEmailBox={setShowCustomerEmailBox}
                />
              }
              isOpen={openIndex === 0}
              toggle={() => toggleAccordion(0)}
            />

            {/* 2) Tech Notes */}
            <Accordion
              title="Tech Notes"
              content={
                <TechNotes
                  inspectionTech={inspectionTech}
                  setInspectionTech={setInspectionTech}
                  urlID={urlID}
                />
              }
              isOpen={openIndex === 1}
              toggle={() => toggleAccordion(1)}
            />

            {/* 3) Additional Tech */}
            <Accordion
              title="Additional Tech"
              content={
                <AdditionalTech
                  selectedTech={selectedTech}   // This is now guaranteed an array
                  setSelectedTech={setSelectedTech}
                  techList={techList}
                  urlID={urlID}
                />
              }
              isOpen={openIndex === 3}
              toggle={() => toggleAccordion(3)}
            />

            {/* 4) Log Books */}
            <Accordion
              title="Log Books (Required)"
              content={
                !showLogBookBox
                  ? <span className='text-red-700'>You need to verify all devices first</span>
                  : <LogBooks
                      logbookstatus={logbookstatus}
                      setLogbookStatus={setLogbookStatus}
                      urlID={urlID}
                      setShowCustomerEmailBox={setShowCustomerEmailBox}
                    />
              }
              isOpen={openIndex === 4}
              toggle={() => toggleAccordion(4)}
            />

            {/* 5) Ticket notification */}
            <Accordion
              title="Ticket notification (Required)"
              content={
                showCustomerEmailBox && (
                  <CustomerEmail
                    inspection_report={inspection_report}
                    setInspectionReport={setInspectionReport}
                    activateSignature={activateSignature}
                    setActivateSignature={setActivateSignature}
                    urlID={urlID}
                    signature={signature}
                  />
                )
              }
              isOpen={openIndex === 5}
              toggle={() => toggleAccordion(5)}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default InspectionDetails;
