import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CotFileUploadModal = ({
  isOpen,
  onClose,
  onSubmit,
  onFileChange,
  accept = 'application/pdf',
  title = 'Upload File',
  label = 'Select a file (Max size 2MB)',
}) => {
  const [fileType, setFileType] = useState(''); // store selected radio value

  if (!isOpen) return null;

  // We’ll handle form submission locally to ensure fileType is selected
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!fileType) {
      toast.error("Please select a file type (TB60, CA or Contract)", { position: 'top-center' });
      return;
    }
    // console.log(fileType, e, 'fileType');
    
    // Call parent onSubmit if needed, passing fileType
    onSubmit(e, fileType);
  };



  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-600 bg-opacity-50">
      <div className="bg-white p-8 rounded-md shadow-md min-w-[500px]">
        <h2 className="text-xl font-semibold mb-4">{title}</h2>
        
        <form onSubmit={handleFormSubmit}>
          {/* Radio Buttons at top for file type */}
          <div className="mb-4">
            <p className="block text-sm font-medium text-gray-700 mb-1">
              Choose File Type (required)
            </p>
            <div className="flex items-center mb-2">
              <input
                type="radio"
                name="fileType"
                value="TB60"
                id="typeTB60"
                className="mr-2"
                onChange={() => setFileType('TB60')}
                required
              />
              <label htmlFor="typeTB60" className="text-sm text-gray-700">
                TB60 Type
              </label>
            </div>

            <div className="flex items-center">
              <input
                type="radio"
                name="fileType"
                value="CA"
                id="typeCA"
                className="mr-2"
                onChange={() => setFileType('CA')}
                required
              />
              <label htmlFor="typeCA" className="text-sm text-gray-700">
                CA File Type
              </label>
            </div>

            <div className="flex items-center mt-2">
              <input
                type="radio"
                name="fileType"
                value="CO"
                className="mr-2"
                onChange={() => setFileType('CO')}
                required
              />
              <label htmlFor="typeContract" className="text-sm text-gray-700">
                Contract File Type
              </label>
            </div>
          </div>

          {/* File Input */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              {label}
            </label>
            <input
              type="file"
              accept={accept}
              onChange={onFileChange}
              required
              className="mt-2"
            />
          </div>

          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 mr-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
            >
              Upload
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CotFileUploadModal;
