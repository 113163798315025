import React from 'react';

// Example color map for each tech. Or generate random on the fly.
const COLORS = [
  '#fca5a5', '#fcd34d', '#86efac', '#93c5fd', 
  '#d8b4fe', '#f9a8d4', '#fdba74', '#cbd5e1',
];

function getRandomColor(techId) {
  const index = Math.abs(String(techId).split('')
    .reduce((acc, c) => acc + c.charCodeAt(0), 0)) % COLORS.length;
  return COLORS[index];
}

// Convert "HH:MM:SS" to a floating hour, e.g. "08:30:00" -> 8.5
function timeStringToFloat(timeStr) {
  if (!timeStr) return 0; 
  const [hh, mm] = timeStr.split(':').map(Number);
  return hh + (mm / 60);
}

// Check if two inspections overlap in time.
function doesOverlap(startA, endA, startB, endB) {
  return startA < endB && endA > startB;
}

// Helper to format an integer hour (0..23) into "12:00 AM", etc.
function formatHour(hour24) {
  const ampm = hour24 < 12 ? 'AM' : 'PM';
  let hour12 = hour24 % 12;
  if (hour12 === 0) hour12 = 12;
  return `${hour12}:00 ${ampm}`;
}

/**
 * Renders a single-day view with a vertical timeline.
 * - Left column = hours in 12h format
 * - Right column = large "timeline"
 * - Overlapping inspections appear side by side.
 */
const DayViewTimeline = ({ 
  selectedDay, 
  inspections, 
  onEventClick, 
  onEmptyClick,
}) => {
  const hourHeight = 77;         // each hour row is 50px tall
  const totalHeight = 24 * hourHeight;

  // Format day label
  const dayLabel = selectedDay.toLocaleDateString('en-US', {
    weekday: 'long', month: 'short', day: 'numeric'
  });

  // Hour labels [0..23]
  const hourLabels = Array.from({ length: 24 }, (_, i) => formatHour(i));

  // We'll map each inspection into an absolutely positioned block
  const blocks = inspections.map((insp, i) => {
    const startH = timeStringToFloat(insp.schedule_time_in || '00:00:00');
    const endH   = timeStringToFloat(insp.schedule_time_out || '23:59:59');
    
    // Enforce end >= start (at least 30 min if reversed)
    const actualStart = Math.max(0, startH);
    const actualEnd   = Math.max(endH, actualStart + 0.5);
    const clippedEnd  = Math.min(24, actualEnd); // clip to 24

    const topPx    = actualStart * hourHeight;
    const heightPx = (clippedEnd - actualStart) * hourHeight;

    // Find all other inspections that overlap in time with "insp"
    const overlapping = inspections.filter((other) => {
      if (other === insp) return true; // obviously overlaps itself
      const oStart = timeStringToFloat(other.schedule_time_in || '00:00:00');
      const oEnd   = timeStringToFloat(other.schedule_time_out || '23:59:59');
      return doesOverlap(actualStart, clippedEnd, oStart, oEnd);
    });

    // Sort them consistently so index is stable
    // e.g., by schedule_time_in ascending
    overlapping.sort((a, b) => {
      const aStart = timeStringToFloat(a.schedule_time_in || '00:00');
      const bStart = timeStringToFloat(b.schedule_time_in || '00:00');
      return aStart - bStart;
    });

    const overlappingCount = overlapping.length;
    const overlappingIndex = overlapping.findIndex(o => o === insp);

    // side-by-side logic
    const blockWidth  = 100 / overlappingCount;  // e.g. 50% if 2 overlap
    const leftOffset  = overlappingIndex * blockWidth;

    // pick color
    const bgColor = getRandomColor(insp.lead_tech_id || insp.id || i);

    return (
      <div
        key={insp.id || i}
        onClick={(e) => {
          e.stopPropagation(); // prevent onEmptyClick from firing
          onEventClick(insp);
        }}
        style={{
          position: 'absolute',
          top:    `${topPx}px`,
          height: `${heightPx}px`,
          left:   `${leftOffset}%`,      // side by side offset
          width:  `${blockWidth}%`,      // side by side width
          backgroundColor: bgColor,
          borderRadius: '4px',
          padding: '4px',
          cursor: 'pointer',
          boxShadow: '0 2px 4px rgba(0,0,0,0.3)',
          color: '#1f2937', 
          overflow: 'hidden',
        }}
      >
        <div style={{ fontWeight: 'bold', fontSize: '0.75rem' }}>
          {insp.job_site_address || 'Unnamed'} | {insp.customer_name || 'Unnamed'}
          <p>{insp.lead_tech_first_name || 'Unnamed'} {insp.lead_tech_last_name || 'Unnamed'}</p>
          {insp.additional_tech_first_name && (
              <p className='text-green-800 font-extrabold'>{insp.additional_tech_first_name || 'Unnamed'} {insp.additional_tech_last_name || 'Unnamed'}</p>
          )}
        </div>
        <div style={{ fontSize: '0.7rem' }}>
          {insp.schedule_time_in} - {insp.schedule_time_out}
        </div>
      </div>
    );
  });

  return (
    <div className="overflow-x-auto">
      {/* Day header */}
      <div className="text-center font-bold mb-2">
        {dayLabel}
      </div>

      {/* 2 columns: left for hour labels, right for timeline */}
      <div style={{ display: 'grid', gridTemplateColumns: '80px 1fr' }}>
        {/* Hour labels column */}
        <div style={{ position: 'relative' }}>
          {hourLabels.map((label, idx) => (
            <div
              key={idx}
              style={{
                height: hourHeight,
                borderBottom: '1px solid #ccc',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {label}
            </div>
          ))}
        </div>

        {/* Timeline column */}
        <div style={{
            position: 'relative',
            height: totalHeight, 
            borderLeft: '1px solid #ccc',
            borderBottom: '1px solid #ccc'}}
          onClick={(e) => {
            // If user clicks an empty area, figure out approximate time
            const offsetY = e.nativeEvent.offsetY;
            const clickedHourFloat = offsetY / hourHeight;
            const hour = Math.floor(clickedHourFloat);
            const minuteFrac = clickedHourFloat - hour;
            const minute = Math.round(minuteFrac * 60);
            onEmptyClick({ hour, minute });
          }}
        >
          {/* hour lines */}
          {hourLabels.map((_, idx) => {
            const lineY = idx * hourHeight;
            return (
              <div
                key={idx}
                style={{
                  position: 'absolute',
                  top: lineY,
                  left: 0,
                  width: '100%',
                  height: 0,
                  borderBottom: '1px solid #eee',
                  pointerEvents: 'none',
                }}
              />
            );
          })}

          {/* The inspection blocks */}
          {blocks}
        </div>
      </div>
    </div>
  );
};

export default DayViewTimeline;
