// InspectionFormModal.jsx
import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';

const InspectionFormModal = ({
  isOpen,
  onClose,
  formData,
  setFormData,
  onSubmit,
  technicians,
  handleDelete,
  isEditing,
  disabled
}) => {
  const [jobSiteQuery, setJobSiteQuery] = useState('');
  const [jobSiteResults, setJobSiteResults] = useState([]);
  const [isJobSiteDropdownVisible, setIsJobSiteDropdownVisible] = useState(false);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleJobSiteInputChange = (e) => {
    const value = e.target.value;
    setJobSiteQuery(value);
    
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    if (value.length >= 3) {
      const timeout = setTimeout(async () => {
        // Fetch job sites from the backend
        try {
          const response = await axios.get(`${apiUrl}/contracts_crud.php`, {
            params: { getJobSites: value },
          });
          const results = Array.isArray(response.data) ? response.data : [];
          setJobSiteResults(results);
          setIsJobSiteDropdownVisible(true);
        } catch (error) {
          // Handle errors
          console.error('Error fetching job sites:', error);
        }
      }, 500); // Delay of 500ms

      setDebounceTimeout(timeout);
    } else {
      setJobSiteResults([]);
      setIsJobSiteDropdownVisible(false);
    }
  };

  const handleJobSiteSelect = (jobSite) => {
    setJobSiteQuery(jobSite.job_site); // Display the selected job site's address
    setIsJobSiteDropdownVisible(false);
    setFormData((prevData) => ({
      ...prevData,
      maintenance_id: jobSite.maintenance_id,
      job_site_name: jobSite.job_site,
      next_due_date: jobSite.next_due_date,
    }));
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-20"
        onClose={onClose}
      >
        {/* Overlay */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-30" />
        </Transition.Child>

        {/* Modal content */}
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-screen items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95 translate-y-4"
              enterTo="opacity-100 scale-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100 translate-y-0"
              leaveTo="opacity-0 scale-95 translate-y-4"
            >
              <Dialog.Panel className="mx-auto max-w-xl w-full bg-white rounded-lg p-6 shadow-lg">
                <Dialog.Title className="text-lg font-medium text-gray-900">
                  {formData.id ? 'Edit Inspection' : 'New Inspection'}
                </Dialog.Title>
                <form onSubmit={onSubmit}>
                  {/* Form fields */}
                  <div className="mt-4 space-y-4">

                    {/* Job Site */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Job Site:
                      </label>
                      <div className="relative">
                        <input
                          type="text"
                          name="job_site"
                          value={jobSiteQuery ? jobSiteQuery : formData.id ? 
                                formData.job_site_address + ', ' 
                                + formData.job_site_city  + ', ' 
                                + formData.job_site_state + ' ' 
                                + formData.job_site_zip : ''
                              }
                          onChange={handleJobSiteInputChange}
                          className="mt-1 block w-full border rounded py-2 px-3"
                          required
                        />
                        {/* Dropdown List */}
                        {isJobSiteDropdownVisible && jobSiteResults.length > 0 && (
                        <ul className="absolute z-10 mt-1 w-full bg-white border rounded shadow-lg max-h-60 overflow-auto divide-y-2">
                          {jobSiteResults.map((jobSite) => (
                            <li
                              key={jobSite.maintenance_id}
                              className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() => handleJobSiteSelect(jobSite)}
                            >
                              <div className="font-medium">{jobSite.job_site}</div>
                              <div className="text-sm text-green-500">
                                Next Inspection Due: {jobSite.next_due_date}
                              </div>
                            </li>
                          ))}
                        </ul>
                        )}
                      </div>
                    </div>

                    {/* Schedule Date From and Schedule Time In */}
                    <div className="flex space-x-4">
                      {/* Schedule Date From */}
                      <div className="flex-1">
                        <label className="block text-sm font-medium text-gray-700">
                          Schedule Date:
                        </label>
                        <input
                          type="date"
                          name="scheduled_due_date"
                          value={formData.scheduled_due_date}
                          onChange={handleInputChange}
                          className="mt-1 block w-full border rounded py-2 px-3"
                          required
                        />
                      </div>

                      {/* Schedule Time In */}
                      <div className="flex-1">
                        <label className="block text-sm font-medium text-gray-700">
                          Schedule Time In:
                        </label>
                        <input
                          type="time"
                          name="schedule_time_in"
                          value={formData.schedule_time_in}
                          onChange={handleInputChange}
                          className="mt-1 block w-full border rounded py-2 px-3"
                          required
                        />
                      </div>
                    </div>

                     {/* Schedule Date To and Schedule Time Out */}
                    <div className="flex space-x-4">
                      {/* Schedule Date To */}
                      <div className="flex-1">
                        <label className="block text-sm font-medium text-gray-700">
                          Schedule End Date:
                        </label>
                        <input
                          type="date"
                          name="schedule_date_to"
                          value={formData.schedule_date_to}
                          onChange={handleInputChange}
                          className="mt-1 block w-full border rounded py-2 px-3"
                          required
                          disabled={disabled}
                        />
                      </div>

                      {/* Schedule Time Out */}
                      <div className="flex-1">
                        <label className="block text-sm font-medium text-gray-700">
                          Schedule Time Out:
                        </label>
                        <input
                          type="time"
                          name="schedule_time_out"
                          value={formData.schedule_time_out}
                          onChange={handleInputChange}
                          className="mt-1 block w-full border rounded py-2 px-3"
                          required
                        />
                      </div>
                    </div>

                    {/* Lead Technician */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Lead Technician:
                      </label>
                      <select
                        name="lead_tech_id"
                        value={formData.lead_tech_id}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border rounded py-2 px-3"
                        required
                      >
                        <option value="">Select Technician</option>
                        {technicians.map((tech) => (
                          <option key={tech.id} value={tech.id}>
                            {tech.first_name} {tech.last_name}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* Additional Technician */}
                    {formData.lead_tech_id && parseInt(formData.lead_tech_id) === parseInt(formData.additional_tech_id) ? (
                      <div className='p-4 text-red-700'>You cant use the same tech as lead tech and additional</div>
                    ):(
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Additional Technician:
                        </label>
                        <select
                          name="additional_tech_id"
                          value={formData.additional_tech_id}
                          onChange={handleInputChange}
                          className="mt-1 block w-full border rounded py-2 px-3">
                          <option value="">Additional Tech</option>
                          {technicians.map((tech) => (
                            <option key={tech.id} value={tech.id}>
                              {tech.first_name} {tech.last_name}
                            </option>
                          ))}
                        </select>
                      </div>                     
                    )}
 

                    {/* Tech Notes */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Inspection Notes:
                      </label>
                      <textarea
                        name="inspection_notes"
                        value={formData.inspection_notes}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border rounded py-2 px-3"
                        rows={4}
                      ></textarea>
                    </div>
                  </div>

                  {/* Buttons */}


                  <div className="mt-6 flex justify-end space-x-2">
                  {isEditing && (
                    <button
                      type="button"
                      onClick={handleDelete}
                      className="inline-flex justify-center rounded-md border border-transparent
                               bg-red-700 px-4 py-2 mr-8 text-sm font-medium text-white shadow-sm hover:bg-red-400">
                      Delete
                    </button>                    
                  )}                    
                    <button
                      type="button"
                      onClick={onClose}
                      className="inline-flex justify-center rounded-md border border-transparent bg-gray-300 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-400"
                    >
                      Cancel
                    </button>

                    <button
                      type="submit"
                      className="inline-flex justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700"
                    >
                      {formData.id ? 'Update' : 'Create'}
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default InspectionFormModal;
