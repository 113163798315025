import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink, useNavigate } from 'react-router-dom';
import SearchBarCP from '../components/SearchBarCP';
import LoadingSpinner from '../components/LoadingSpinner';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { dateToUnixTimestamp } from '../utils/dateToUnixTimestamp';
import DateFormat from '../components/DateFormat';
import CurrencyFormat from '../components/CurrencyFormat';
import { useReactToPrint } from 'react-to-print';
import '../styles/PayrollPage.css'
import HeadingCp from '../components/HeadingCp';
import MonthSelectorCp from '../components/MonthSelectorCp';

const PayrollPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [fetchItems, setFetchItems] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [weekDays, setWeekDays] = useState([]);
  const [week, setWeek] = useState('active_week');
  const [payperiodMonth, setPayPeriodMonth] = useState(null);
  const [payperiod, setPayPeriod] = useState(null);
  const [holiday, setHoliday] = useState(null);
  const [onCallTechs, setOnCallTechs] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('');

  const handleMonthChange = (month) => {
    setSelectedMonth(month);
  };  

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const parseDateString = (dateString) => { //modify for 1 week payroll
    const [year, month, day] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day); // Months are zero-based
  };

  const formatDateHeader = (dateString) => { //modify for 1 week payroll
    const date = parseDateString(dateString);
    const day = date.toLocaleDateString('en-US', { day: '2-digit', month: '2-digit' });
    const weekday = date.toLocaleDateString('en-US', { weekday: 'short' });
    return (
      <>
        <div>{day}</div>
        <div>{weekday}</div>
      </>
    );
  };

  const getDateRequestColor = (date_request) => {
  if (date_request.toLowerCase().includes('no pay')) {
    return 'bg-red-400'; // Matches any string containing "No Pay"
  }

  switch (date_request) {
    case 'in':
      return 'bg-green-300';
    case 'Vacation Day':
      return 'bg-blue-300';
    case 'Half Vacation Day':
      return 'bg-orange-300';
    case 'Sick Day':
      return 'bg-indigo-300';
    case 'Excused Pay':
      return 'bg-red-300';
    case 'Partial Hour':
      return 'bg-red-300';
    default:
      return '';
    }
  };

  const tableRef = useRef();

  const handlePrint = () => {
    window.print()
  }  

  const handleSearchClick = async () => {
    if (!searchTerm) {
      toast.info('Search bar empty', { position: 'top-center' });
    } else {
      setLoading(true);

      const bodyData = {
        status: 'searchDelivery',
        data: { search: searchTerm }
      };

      try {
        const response = await axios.post(`${apiUrl}/wip_crud.php`, bodyData, {
          headers: { 'Content-Type': 'application/json' }
        });
        if (response.data && Array.isArray(response.data)) {
          setFetchItems(response.data);
          setFilterItems(response.data);
        } else {
          handleSearchChange();
          setFetchItems([]);
          setFilterItems([]);
          toast.error(`Not found: ${searchTerm}`, { position: 'top-center' });
        }
      } catch (error) {
        console.error('Error searching deliveries', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value === '') {
      setFilterItems(fetchItems);
    } else {
      const filtered = fetchItems.filter(object =>
        (object.description && object.description.toLowerCase().includes(value.toLowerCase())) ||
        (object.job_number && object.job_number.toLowerCase().includes(value.toLowerCase())) ||
        (object.job_site && object.job_site.toLowerCase().includes(value.toLowerCase()))
      );
      setFilterItems(filtered);
    }
  };

  const handleWeek = (e, date) => {
    e.preventDefault();
    setWeek(date);
  };

  const isOnCallForDate = (date, employeeId) => {
    if(onCallTechs === 'not on call') {
      return
    }
    const currentDate = new Date(date).setHours(0, 0, 0, 0); // Normalize the date

    return onCallTechs.some((tech) => {
      const techStart = new Date(tech.start_date).setHours(0, 0, 0, 0);
      const techEnd = new Date(tech.end_date).setHours(0, 0, 0, 0);

      return (
        tech.employee_id === employeeId && // Check if tech matches the employee
        currentDate >= techStart &&
        currentDate <= techEnd // Check if date is within range
      );
    });
  };

  useEffect(() => {
    setLoading(true);
    fetchEmployeePayroll(selectedMonth, week, apiUrl, navigate);
  }, [selectedMonth, week, apiUrl, navigate]);

  const fetchEmployeePayroll = async (selectedMonth, week, apiUrl, navigate) => {
    try {
      const response = await axios.get(`${apiUrl}/payroll_crud.php?payroll=${week}&&selectedMonth=${selectedMonth}`, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } });
      const payroll = Array.isArray(response.data.employees) ? response.data.employees : [];
      if (payroll.length === 0) {
        toast.error("No payroll report found!", { position: "top-center" });
      }
      setFilterItems(payroll || []);
      setFetchItems(payroll || []);
      setWeekDays(response.data.pay_period.week || []);
      setPayPeriodMonth(response.data.pay_periods_month);
      setPayPeriod(response.data.date);
      setHoliday(response.data.holiday);
      setOnCallTechs(response.data.onCall || []);
      
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", { position: "top-center" });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", { position: "top-center" });
      }
    } finally {
      setLoading(false);
    }
  };


  // -----------------------------------------------------------
  //  NEW FUNCTION: handleExportCSV to create a CSV string and trigger download
  // -----------------------------------------------------------
  const handleExportCSV = () => {
    if (!filterItems || filterItems.length === 0) {
      toast.info("No data to export.", { position: 'top-center' });
      return;
    }

    // 1) Create an array of row strings
    //    For example, let's export: Employee Name, TH, OT, RH, VH, HH
    //    plus some sample columns.
    //    You can add or remove columns as you see fit.

    // HEADERS:
    const headers = [
      "ID",
      "Employee Name",
      "Department",
      "TH",
      "OT",
      "RH",
      "VH",
      "HH",
    ];
    // We'll do array-of-arrays, each representing one row
    const rows = [headers];

    // 2) Build data rows
    filterItems.forEach(item => {
      const row = [
        item.id,
        `"${item.employee_name}"`,   // wrap quotes in case of commas
        item.department,
        item.RH + item.VH + item.HH, // TH (just an example)
        item.OT,
        item.RH,
        item.VH,
        item.HH,
      ];
      rows.push(row);
    });

    // 3) Convert arrays to CSV
    const csvContent = rows.map(rowArr => rowArr.join(",")).join("\n");

    // 4) Create a Blob and a download link
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.style.display = "none";
    link.setAttribute("download", "payroll.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // ...

  const filterWeekDays = (days) => {
    return days.filter((dayString) => {
      const date = new Date(`${dayString}T00:00:00Z`); // Force UTC
      const dayOfWeek = date.getUTCDay(); 
      return dayOfWeek !== 0 && dayOfWeek !== 6;
    });
  };

  const filterWeekDaysHeader = (days) => {
    return days.filter(day => {
      const date = new Date(day);
      const dayOfWeek = date.getDay();
      return dayOfWeek !== 0 && dayOfWeek !== 6; // Exclude Saturday (6) and Sunday (0)
    });
  };

  const getEmployeeDateRequest = (days, date) => {
     const formattedDate = new Date(date).toISOString().split('T')[0]; //modify for 1 week payroll
    const day = days.find(d => d.date_out === formattedDate);

    if (!day) {
      return 'NS'; // Return 'NS' if no matching day is found
    }

    if (day.date_request === 'Partial Hour') {
      return `${day.date_request} ${day.partial_hours}`;
    }

    if (day.date_request === 'No Pay') {
      return `${day.date_request} - ${day.notes}`;
    }

    return day.date_request; // Default to returning date_request
  };

  const filteredWeekDays = filterWeekDays(weekDays); //modify for 1 week payroll
  const filteredWeekDaysHeader = filteredWeekDays; //modify for 1 week payroll

  const getEmployeeVacation = (vacationDays, date) => {
    return vacationDays.includes(date) ? 'Vacation Day' : 'NS';
  };

  return (
    <div className="mx-auto max-w-6xl sm:px-4 lg:px-6 py-10">


      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <HeadingCp label="Payroll Report" />
        </div>

      <div className="mt-4 sm:mt-0 sm:ml-4">
        <button
          onClick={handlePrint}
          className="inline-flex items-center px-4 py-2 border border-transparent 
            text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
        >
          Print
        </button>

          {/* 5) Add "Export CSV" Button */}
          <button
            onClick={handleExportCSV}
            className="inline-flex items-center px-4 py-2 
                       border border-transparent text-sm font-medium 
                       rounded-md shadow-sm text-white bg-blue-600 
                       hover:bg-blue-700 ml-2"
          >
            Export CSV
          </button>        
      </div>
      </div>
      
      <div className="mt-2 sm:mt-0 sm:ml-2 w-[15%] mb-4">
        <MonthSelectorCp onMonthChange={handleMonthChange} />
      </div>

      {payperiodMonth ? (
        <>
          {payperiodMonth.map((pay_period_week, index) => {
            return (
              <span key={pay_period_week} className="px-2 mt-2">
                <button
                  className={`px-4 py-1 text-white rounded-md hover:bg-blue-600 ${pay_period_week === payperiod ? 'bg-green-500' : 'bg-blue-500'} `}
                  onClick={(e) => handleWeek(e, pay_period_week)}>
                  <DateFormat date={pay_period_week} />
                </button>
              </span>
            );
          })}
        </>
      ) : (
        <LoadingSpinner children={'Loading...'}/>
      )}

      <div className="mt-8 flow-root">
        <div className="-mx-1 -my-1 sm:-mx-2 lg:-mx-3">
          <div className="sm:flex sm:items-center py-6">
            <div className="flex w-full items-center mt-5 sm:mt-0">
              <div className="flex-grow">
                {/* <SearchBarCP search={searchTerm} handleChange={handleSearchChange} onClick={handleSearchClick} /> */}
              </div>
            </div>
          </div>

          {/* <div className="inline-block min-w-full py-2 align-middle"> */}
          <div ref={tableRef} className="mt-8 flow-root">
            {loading ? (
              <LoadingSpinner children={'Loading...'}/>
            ) : (
              <div className="shadow ring-1 ring-black ring-opacity-5 max-w-[1153px] mx-auto overflow-auto">
                <table className="min-w-full border-separate border-spacing-0">
                  <thead className="top-0 z-30 bg-white">
                    <tr>                      
                      <th className="top-0 z-30 border-b border-gray-300 bg-white bg-opacity-75 py-1.5 pl-2 pr-2 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-3 lg:pl-4">
                        Employee Name
                      </th>
                      <th className="top-0 z-30 hidden border-b border-gray-300 bg-white bg-opacity-75 px-2 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                        TH
                      </th>
                      <th className="top-0 z-30 hidden border-b border-gray-300 bg-white bg-opacity-75 px-2 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                        OT
                      </th>                                             
                      <th className="top-0 z-30 hidden border-b border-gray-300 bg-white bg-opacity-75 px-2 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                        RH
                      </th>
                      <th className="top-0 z-30 hidden border-b border-gray-300 bg-white bg-opacity-75 px-2 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                        VH
                      </th>
                      <th className="top-0 z-30 hidden border-b border-gray-300 bg-white bg-opacity-75 px-2 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                        HH
                      </th>
                      {filteredWeekDaysHeader.map((date) => (
                        <th key={date} className="top-0 z-30 border-b border-gray-300 bg-white bg-opacity-75 py-1.5 pl-2 pr-2 backdrop-blur backdrop-filter sm:pr-3 lg:pr-4">
                          {formatDateHeader(date)}
                        </th>
                      ))}
                      <th className="top-0 z-30 hidden border-b border-gray-300 bg-white bg-opacity-75 px-2 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                        Expense | Notes
                      </th>                      
                    </tr>
                  </thead>

                  <tbody>
                    {filterItems.map((object, objectIdx) => {
                      return (
                        <tr key={object.id} className={`${objectIdx % 2 === 0 ? 'bg-white' : 'bg-gray-100'} divide-x divide-y divide-gray-300 text-sm`}>
 
                          <td className='px-2 py-1'>
                            <span className='text-blue-800'>
                              <NavLink to={`/profile/${object.id}`}>{object.employee_name}</NavLink>
                            </span>
                            <br/>
                            <span className='text-gray-500 text-xs'>({object.id}) {object.department}</span>
                          </td>

                          <td className={`px-2 py-1 ${
                                            parseFloat(object.RH) + parseFloat(object.VH) > 40 
                                              ? 'bg-red-500' 
                                              : parseFloat(object.RH) + parseFloat(object.VH) < 40 
                                                ? 'bg-[#fed7aa]' 
                                                : 'bg-[#bfdbfe]'
                                          }`}>
                            TH {parseFloat(object.RH) + parseFloat(object.VH) + parseFloat(object.HH) || 0}
                          </td>                          
 
                          <td className={`px-2 py-1 ${object.OT > 0? 'bg-purple-300' : ''}`}>
                            OT {parseFloat(object.OT)}
                          </td>  

                          <td className="px-2 py-1">
                            RH {parseFloat(object.RH)}
                          </td>                                             
                     
                          <td className="px-2 py-1">
                            VH {parseFloat(object.VH)}
                          </td>
  
                          <td className="px-2 py-1">
                            HH {parseFloat(object.HH) || 0}
                          </td>

                            {filteredWeekDays.map((date) => (
                              <td
                                key={date}
                                className={`px-2 py-1 
                                  ${holiday && holiday.includes(date) ? 'bg-yellow-300' : ''} 
                                  ${getDateRequestColor(getEmployeeDateRequest(object.days, date))}
                                  ${getDateRequestColor(getEmployeeVacation(object.vacation_days, date))}
                                  ${isOnCallForDate(date, object.id) ? ' text-violet-600' : ''}
                                  text-center`}
                              >
                                {/* Display both "On-Call" and other content */}
                                {holiday && holiday.includes(date) ? (
                                  'Holiday'
                                ) : (
                                  <>
                                    {/* Existing content */}
                                    {getEmployeeDateRequest(object.days, date) !== 'NS'
                                      ? getEmployeeDateRequest(object.days, date)
                                      : getEmployeeVacation(object.vacation_days, date)}
                                    {isOnCallForDate(date, object.id) && (
                                      <>
                                        <br /> {/* Add a line break */}
                                        <span>On-Call</span>
                                      </>
                                    )}
                                  </>
                                )}
                              </td>
                            ))}
                          {/* this is for expenses */}
                          <td>
                            {object.o_f_type === 'Field' ? (
                              object.expense.expense_amount && (
                                <span className='text-purple-700 ml-4'><CurrencyFormat value={object.expense.expense_amount} /></span>
                              )
                            ) : (
                              object.officeExpense && (
                                <>
                                  <span className='text-purple-700 ml-2'>
                                  <CurrencyFormat value={object.officeExpense} />
                                  </span>
                                  <span>
                                    {object.notes}
                                  </span>
                                </>
                              ))}
                          </td>

                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PayrollPage;
