import React, { forwardRef, useState, useEffect } from 'react';
import  axios  from 'axios';
import {useNavigate, useParams} from 'react-router-dom'
import FormHeader from './FormHeader';
import useSubmitPost from '../hooks/handleSubmitPost';
import SubmitButton from './SubmitButton';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import { getTodaysDateUtil } from '../utils/DateFormatTodayUtil';

const ModalOvertimeSection = ({ api, val, onChange, bodyData, redirectPath, closeNotes, deleteOT, handleNoteChangeParent, deleteButton}) => {
  const handleSubmit = useSubmitPost();
  const initialDate = val.start_date ? val.start_date : getTodaysDateUtil() ;
  const navigate = useNavigate();
  const { key } = useParams();
  const [loading, setLoading] = useState([]);
  const [week, setWeek] = useState([]);
  const [access, setAccess] = useState(false);
  const [deletehrs, setDeleteHrs] = useState('');

  const apiUrl = process.env.REACT_APP_API_URL;

 const handleDeleteDay = async (employee, e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${apiUrl}/settings_crud.php`, {
        status: 'delete_ot',
        data: { ot_id: employee.ot_id },
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data.success) {
        toast.success("Overtime deleted successfully!", {
          position: "top-center"
        });
          handleNoteChangeParent(employee.ot_id, employee.employee);    
      } else {
        toast.error(response.data.message || "Failed to delete overtime.", {
          position: "top-center"
        });
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.", {
        position: "top-center"
      });
    }
  }

  return (
    <>
      <div className="flex items-center justify-center py-5 fit-content">
        <form
          onSubmit={(event) => handleSubmit(event, {
            bodyData,
            api,
            errorLabel: 'Overtime',
            // redirectPath: redirectPath,
          })}
          className="w-full rounded-lg"
        >
          <div className="space-y-12">
            <FormHeader type={'Submit'} what={'Carry-Over Day(s)'} />
            <div className="sm:col-span-2">
            <label htmlFor="total_days" className="block text-sm font-medium leading-6 text-gray-900">
                Days <span className='text-red-500'>*</span>
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  name="total_days"
                  id="total_days"
                  maxLength="3"
                  minLength="1"
                  value={val.total_days || ''} 
                  onChange={onChange}
                  required={false}
                  className="text-slate-500 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="mt-6 flex items-center justify-between gap-x-6">
              {
                val.days ?  
                <button
                  type="button"
                  className="mt-3 inline-flex justify-center 
                            rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white 
                            shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-red-300 sm:mt-0 sm:w-auto"
                  onClick={(e) => handleDeleteDay(deleteOT, e)}>
                  Delete Days
                </button>
                :
                ''
              }

              <div className="ml-auto flex items-center gap-x-6">
                <button
                  type="button"
                  className="mt-3 inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  onClick={closeNotes}>
                  Close
                </button>

                <SubmitButton>Submit</SubmitButton>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ModalOvertimeSection;
